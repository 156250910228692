import React, { Suspense } from "react";
import { createBrowserRouter } from "react-router-dom";
import LoadingFallback from "./components/Fallback";

import NoPlanetAvailable from "./pages/NoPlanetAvailable";
import OSOS from "./components/main/maps/OSOS";
import World from "./components/main/planet/World";
import Landing from "./pages/Landing";

// Dynamic imports for large components
const Layout = React.lazy(() => import("./pages/Layout"));
const Home = React.lazy(() => import("./pages/Home"));
const Start = React.lazy(() => import("./pages/Start"));
const Protected = React.lazy(() => import("./pages/Protected"));
const ErrorPage = React.lazy(() => import("./pages/ErrorPage"));

const routes = createBrowserRouter([
  {
    path: "/",
    element: (
      <Suspense fallback={<LoadingFallback />}>
        <Landing />
      </Suspense>
    ),
    errorElement: (
      <Suspense fallback={<LoadingFallback />}>
        <ErrorPage />
      </Suspense>
    ),
  },
  {
    path: "/nps",
    element: (
      <Suspense fallback={<LoadingFallback />}>
        <Start />
      </Suspense>
    ),
  },
  {
    path: "/npa",
    element: (
      <Suspense fallback={<LoadingFallback />}>
        <NoPlanetAvailable />
      </Suspense>
    ),
  },
  {
    path: "/g",
    element: (
      <Suspense fallback={<LoadingFallback />}>
        <Protected />
      </Suspense>
    ),
    errorElement: (
      <Suspense fallback={<LoadingFallback />}>
        <ErrorPage />
      </Suspense>
    ),
    children: [
      {
        element: (
          <Suspense fallback={<LoadingFallback />}>
            <Layout />
          </Suspense>
        ),
        children: [
          {
            index: true,
            element: (
              <Suspense fallback={<LoadingFallback />}>
                <World />
              </Suspense>
            ),
          },
          {
            path: "/g/h",
            element: (
              <Suspense fallback={<LoadingFallback />}>
                <Home />
              </Suspense>
            ),
          },
          {
            path: "/g/osos",
            element: (
              <Suspense fallback={<LoadingFallback />}>
                <OSOS />
              </Suspense>
            ),
          },
        ],
      },
    ],
  },
]);

export default routes;
