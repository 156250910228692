import { Box, Divider, Heading, Image, Tooltip, Text } from "@chakra-ui/react";
import { TCoords, PlanetMapDTO } from "../../../structs/dtos/Empire";
import { Planet } from "../../../structs/Enums";
import space from "../../../assets/backgrounds/space.png";
import { useEffect, useState } from "react";
import useStoreUniverse from "../../../services/stores/useStoreUniverse";
import enumToImage from "../../../services/image";
import Sun from "../../../assets/planets/Sun.png";
import useStoreResearchTree from "../../../services/stores/useStoreResearchTree";
import Encyclopedia, { Chapter, Key } from "../../../services/encyclopedia";
import { toNumberStr, toTimeStr } from "../../../global";

interface Props {
  planets: PlanetMapDTO[];
}

interface Dimensions {
  width: number;
  height: number;
}

const SolarSystem = ({ planets }: Props) => {
  const ENCY = new Encyclopedia();
  const overlap = (entityA: TCoords, entityB: TCoords, radiusA: number, radiusB: number) => {
    const distance = Math.sqrt(Math.pow(entityA.x - entityB.x, 2) + Math.pow(entityA.y - entityB.y, 2));
    const scaled = distance * scale;
    if (scaled < radiusA + radiusB) {
      const angle = Math.atan2(entityB.y - entityA.y, entityB.x - entityA.x);
      const overlap = (radiusA + radiusB - scaled) / scale;
      entityB.x += overlap * Math.cos(angle);
      entityB.y += overlap * Math.sin(angle);
    }
  };
  const distance = (entityA: TCoords, entityB: TCoords) => {
    return Math.sqrt(Math.pow(entityB.x - entityA.x, 2) + Math.pow(entityB.y - entityA.y, 2));
  };
  const duration = (D: number, s: number) => {
    const G = 1;
    return ((2700 * D) / (s * 0.25)) * G;
  };

  const currPlanet = useStoreUniverse((state) => state.currPlanet);
  const { starCharts } = useStoreResearchTree((state) => state);
  if (!currPlanet) return <div />;

  const [canvas, setCanvas] = useState<Dimensions>({ width: 0, height: 0 });

  const glow = "0 0 6px 3px #fff, 0 0 10px 6px #f0f, 0 0 14px 9px #0ff;";

  useEffect(() => {
    const dimensions = () => {
      const container = document.getElementById("solarSystem");
      if (container) {
        setCanvas({
          width: container.clientWidth,
          height: container.clientHeight,
        });
      }
    };

    dimensions();
    window.addEventListener("resize", dimensions);

    return () => {
      window.removeEventListener("resize", dimensions);
    };
  }, []);

  const containerSize = Math.min(canvas.width, canvas.height);
  const solarSystemSize = 30.05;
  const scale = containerSize / (2 * solarSystemSize);

  const sunVisualSize = containerSize * 0.06;
  const planetVisualSize = containerSize * 0.04;

  const position = (
    coords: TCoords,
    radius: number
  ): {
    position: "absolute";
    left: string;
    top: string;
    transform: string;
  } => {
    const scaledX = coords.x * scale;
    const scaledY = coords.y * scale;
    return {
      position: "absolute",
      left: `calc(50% + ${scaledX}px)`,
      top: `calc(50% - ${scaledY}px)`,
      transform: `translate(-${radius}px, -${radius}px)`,
    };
  };

  const adjusted = planets.map((planet, index) => {
    const newCoords = { ...planet.coords };
    overlap({ x: 0, y: 0 }, newCoords, sunVisualSize / 2, planetVisualSize / 2);
    for (let i = 0; i < index; i++) {
      overlap(planets[i].coords, newCoords, planetVisualSize / 2, planetVisualSize / 2);
    }
    return { ...planet, coords: newCoords };
  });

  return starCharts?.researched ? (
    <Box id="solarSystem" position="relative" w="full" h="full" bgImage={space} bgSize="cover" overflow="hidden">
      <Box {...position({ x: 0, y: 0 }, sunVisualSize / 2)} width={`${sunVisualSize}px`} height={`${sunVisualSize}px`} borderRadius="full" display="flex" alignItems="center" justifyContent="center">
        <Tooltip
          placement="top"
          label={
            <Box display="flex" flexDirection="column" alignItems="center">
              <Heading size="sm">SUN</Heading>
              <Divider />
              <Text fontSize="xs">{toNumberStr(distance(currPlanet.coords, { x: 0, y: 0 }))} AU</Text>
              <Text fontSize="xs">{toTimeStr(duration(distance(currPlanet.coords, { x: 0, y: 0 }), 1))} @ 1SL/s</Text>
            </Box>
          }
        >
          <Box w="full" h="full">
            <Image borderRadius="full" alt="sun" src={Sun} draggable={false} />
          </Box>
        </Tooltip>
      </Box>
      {adjusted.map((planet) => (
        <Box
          key={planet.id}
          {...position(planet.coords, planetVisualSize / 2)}
          width={`${planetVisualSize}px`}
          height={`${planetVisualSize}px`}
          boxShadow={planet.id === currPlanet?.id ? glow : ""}
          borderRadius="full"
          display="flex"
          alignItems="center"
          justifyContent="center"
          cursor="help"
          border={0}
        >
          <Tooltip
            placement="top"
            label={
              planet.name.length > 0 ? (
                <Box display="flex" flexDirection="column" alignItems="center">
                  <Heading size="sm">{planet.name}</Heading>
                  <Image borderRadius="full" alt={planet.name} src={enumToImage(Planet, planet.type)} draggable={false} />
                  <Divider />
                  <Text fontSize="xs">{ENCY.word(Chapter.Planet, planet.type, Key.Name)}</Text>
                  <Text fontSize="xs">
                    {toNumberStr(planet.coords.x)},{toNumberStr(planet.coords.y)}
                  </Text>
                </Box>
              ) : (
                <Box display="flex" flexDirection="column" alignItems="center">
                  <Heading size="sm">Unknown Planet</Heading>
                  <Image borderRadius="full" alt={planet.name} src={enumToImage(Planet, planet.type)} draggable={false} />
                  <Divider />
                  <Text fontSize="xs">{ENCY.word(Chapter.Planet, planet.type, Key.Name)}</Text>
                  <Text fontSize="xs">
                    {toNumberStr(planet.coords.x)},{toNumberStr(planet.coords.y)}
                  </Text>
                  <Divider />
                  <Text fontSize="xs">{toNumberStr(distance(currPlanet.coords, planet.coords))} AU</Text>
                  {currPlanet.id !== planet.id && <Text fontSize="xs">{toTimeStr(duration(distance(currPlanet.coords, planet.coords), 1))} @ 1SL/s</Text>}
                </Box>
              )
            }
          >
            <Image borderRadius="full" alt={planet.name} src={enumToImage(Planet, planet.type)} draggable={false} />
          </Tooltip>
        </Box>
      ))}
    </Box>
  ) : (
    <Box id="solarSystem" position="relative" w="full" h="full" bgImage={space} bgSize="cover" overflow="hidden">
      {/* Sun */}
      <Box {...position({ x: 0, y: 0 }, sunVisualSize / 2)} width={`${sunVisualSize}px`} height={`${sunVisualSize}px`} borderRadius="full" display="flex" alignItems="center" justifyContent="center">
        <Box w="full" h="full">
          <Image borderRadius="full" alt="sun" src={Sun} draggable={false} />
        </Box>
      </Box>
      {adjusted.map((planet) => (
        <Box
          key={planet.id}
          {...position(planet.coords, planetVisualSize / 2)}
          width={`${planetVisualSize}px`}
          height={`${planetVisualSize}px`}
          boxShadow={planet.id === currPlanet?.id ? glow : ""}
          borderRadius="full"
          display="flex"
          alignItems="center"
          justifyContent="center"
          border={0}
        >
          <Image borderRadius="full" alt={planet.name} src={enumToImage(Planet, planet.type)} draggable={false} />
        </Box>
      ))}
    </Box>
  );
};

export default SolarSystem;
