export const online = () => {
	return navigator.onLine;
};
const internet = async () => {
	try {
		if (!online()) return false;

		await fetch('https://www.google.com/', {
			method: 'HEAD',
			mode: 'no-cors',
		});
		return true;
	} catch (e) {
		return false;
	}
};
export default internet;
