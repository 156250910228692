import { create } from "zustand";
import { subscribeWithSelector } from "zustand/middleware";

import { GalaxyDTO, PlanetDTO, SolarSystemDTO } from "../../structs/dtos/Empire";

interface IStoreUniverse {
  planets: PlanetDTO[];
  currPlanet: PlanetDTO | undefined;
  galaxy: GalaxyDTO | undefined;
  solarSystem: SolarSystemDTO | undefined;
  setPlanets: (planets: PlanetDTO[]) => void;
  setCurrentPlanet: (currPlanet: PlanetDTO) => void;
  setGalaxy: (galaxy: GalaxyDTO) => void;
  setSolarSystem: (solarSystem: SolarSystemDTO) => void;
}

const useStoreUniverse = create<IStoreUniverse, [["zustand/subscribeWithSelector", never]]>(
  subscribeWithSelector<IStoreUniverse>((set) => ({
    planets: [],
    currPlanet: undefined,
    galaxy: undefined,
    solarSystem: undefined,
    setPlanets: (planets) => set(() => ({ planets })),
    setCurrentPlanet: (currPlanet) => set(() => ({ currPlanet })),
    setGalaxy: (galaxy) => set(() => ({ galaxy })),
    setSolarSystem: (solarSystem) => set(() => ({ solarSystem })),
  }))
);
export default useStoreUniverse;
