import { Box, Flex, Grid, GridItem, Heading, Table, TableContainer, Tbody, Td, Text, Tfoot, Th, Thead, Tr } from "@chakra-ui/react";
import useStoreUniverse from "../../../services/stores/useStoreUniverse";
import { toUTCDateString } from "../../../global";
import Encyclopedia, { Chapter, Key } from "../../../services/encyclopedia";
import { useMemo } from "react";
import useDataTable from "../../control/useDataTable";
import GameButton from "../../control/GameButton";

const World = () => {
  const ency = new Encyclopedia();
  const planet = useStoreUniverse((state) => state.currPlanet);
  if (planet == undefined) return <div />;

  const history = useMemo(
    () =>
      Object.keys(planet.history || [])
        .map((key) => {
          return {
            timestamp: key,
            ...planet.history[key],
          };
        })
        .sort((a, b) => b.timestamp.localeCompare(a.timestamp)),
    [planet.history]
  );
  const { dataTable, pageCount, page, prev, next } = useDataTable<any>({ data: history, rows: 20 });

  return (
    planet && (
      <Flex direction="column" height="100%" p={4} gap={4}>
        <Box flex="0 1 auto">
          <Box w="full" p={4}>
            <Heading>{planet.name}</Heading>
            <Box textAlign="left" overflow="hidden">
              <Text fontSize="sm">{planet.description}</Text>
            </Box>
          </Box>
        </Box>
        <Box flex="1" overflow="hidden">
          <Box p={4} textAlign="left" height="100%" overflow="auto">
            <Box textAlign="left">
              <Heading size="md">Planet</Heading>
              <Text>{ency.word(Chapter.Planet, planet.type, Key.Name)}</Text>
            </Box>
            <Box textAlign="left">
              <Heading size="md">Race</Heading>
              <Text>{ency.word(Chapter.Race, planet.race, Key.Name)}</Text>
              <Grid templateColumns="1fr 5fr">
                <GridItem>Characteristics</GridItem>
                <GridItem>{ency.word(Chapter.Race, planet.race, Key.Characteristics)}</GridItem>
                <GridItem>Abilities</GridItem>
                <GridItem>{ency.word(Chapter.Race, planet.race, Key.Abilities)}</GridItem>
              </Grid>
            </Box>
            <Box textAlign="left">
              <Heading size="md">
                Location {planet.coords.galaxy}°{planet.coords.solarSystem}"{planet.coords.x.toFixed(2)},{planet.coords.y.toFixed(2)}
              </Heading>
              <Grid templateColumns="1fr 5fr">
                <GridItem>Galaxy</GridItem>
                <GridItem>{planet.coords.galaxy}</GridItem>
                <GridItem>Solar System</GridItem>
                <GridItem>{planet.coords.solarSystem}</GridItem>
              </Grid>
            </Box>
            <Heading size="md">History</Heading>
            <Box textAlign="left" overflow="auto">
              {dataTable && (
                <TableContainer>
                  <Table size="sm">
                    <Thead>
                      <Tr>
                        <Th w="100px">Date</Th>
                        <Th>Event</Th>
                      </Tr>
                    </Thead>
                    <Tbody overflowY="auto">
                      {dataTable.map((row) => (
                        <Tr key={row.timestamp} fontSize="sm">
                          <Td>{toUTCDateString(row.date)}</Td>
                          <Td>{row.event}</Td>
                        </Tr>
                      ))}
                    </Tbody>
                    {pageCount > 1 && (
                      <Tfoot>
                        <Tr>
                          <Th colSpan={2}>
                            <Flex gap={1} pt={1}>
                              <GameButton size="xs" onClick={prev} isDisabled={page === 1}>
                                PREV
                              </GameButton>
                              <GameButton size="xs" onClick={next} isDisabled={page === pageCount}>
                                NEXT
                              </GameButton>
                            </Flex>
                          </Th>
                          <Th isNumeric>
                            Page {page} of {pageCount}
                          </Th>
                        </Tr>
                      </Tfoot>
                    )}
                  </Table>
                </TableContainer>
              )}
            </Box>
          </Box>
        </Box>
      </Flex>
    )
  );
};

export default World;
