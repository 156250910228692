import useSound from "use-sound";
import { AudioTarget, getAudioFile } from "../audio";
import { useEffect, useState } from "react";
import useStoreSettings from "../stores/useStoreSettings";

const useAudioSFX = (audio: AudioTarget) => {
  const file = getAudioFile(audio);
  const { volumeMaster, volumeSFX } = useStoreSettings((store) => store);
  const [volume, setVolume] = useState(volumeMaster * volumeSFX);
  const [playing, setPlaying] = useState(false);

  const [play, { stop, sound }] = useSound(file, { volume, onplay: () => setPlaying(true), onend: () => setPlaying(false) });
  const onStop = () => {
    stop();
    setPlaying(false);
  };

  useEffect(() => {
    setVolume(volumeMaster * volumeSFX);
  }, [volumeMaster, volumeSFX]);
  useEffect(() => {
    if (sound) {
      sound.volume(volume);
    }
  }, [volume]);

  return { play, stop: onStop, playing };
};

export default useAudioSFX;
