export enum Splash {
  Landing,
  Creating,
}
export enum GlobalIcon {
  App,
}

/*export enum NavbarItem {
  Home,
  Civilization,
  Citizens,
  Nourishment,
  Residence,
  PowerSrc,
  Excavation,
  Education,
  Technology,
  Research,
  Manufacturing,
  Hangar,
  OSOS,
  Expeditions,
  Events,
  CMD,
  WeatherBureau,
}*/

export enum ToolButtons {
  WeatherBureau,
}
export enum MenuButtons {
  Home,
  Civilization,
  Citizens,
  Nourishment,
  Residence,
  PowerSrc,
  Excavation,
  Education,
  Technology,
  Research,
  Manufacturing,
  Hangar,
  OSOS,
  Expeditions,
  Events,
}
export enum EmpireButtons {}

export enum GameButtons {
  Settings,
  CMD,
}

export enum GameAction {
  Timestamp,
  Planets,
  CurrentPlanet,
  WebWork,
}
/*ENGINE TASK & EVENT*/
export enum ServerUpdate {
  ServerTime,
  Universe,
  OnlinePlayers,
  OnConnectPlanets,
  PlanetUpdate,
  Failed,
  Ok,
  Error,
  Banner,
  DuplicateConnection,
  Player,
  NoUserFound,
  UpdateEvent,
  Galaxy,
  SolarSystem,
  NoPlanetAvailable,
  NoPlanetSelected,
  Encyclopedia,
}
export enum ClientUpdate {
  Ping,
  Building,
  Save,
  Settings,
  OSOS_Scan,
  Evolve,
  Citizen,
  Demote,
  Demolish,
  Current,
  CMD,
  Expedition,
  Event,
  Research,
  Machine,
  Vehicle,
}
export enum UpdateEvent {
  Resource,
  Building,
  Ok,
  Failed,
  Citizen,
  Research,
  Machine,
  Vehicle,
  DateTime,
  Expedition,
  GameEvent,
  Populace,
}
export enum GameEvents {
  BuildingBuild,
  BuildingUpgraded,
  BuildingDemolished,
  ExpeditionCompleted,
  CivilizationEvolved,
  ResearchCompleted,
}
/*PLANET DATA & EVENT*/
export enum Planet {
  Terran,
  GasGiant,
  Frozen,
  Lava,
  Oceanic,
  Desert,
  Hollow,
}
/*TODO REMOVE THIS*/
export enum BG_Planet {
  Terran,
  GasGiant,
  Frozen,
  Lava,
  Oceanic,
  Desert,
  Hollow,
}
export enum Occupied {
  None,
  Origin,
  Diplomacy,
  Conquered,
  Other,
}
export enum WeatherEvent {
  ClearSkies,
  Humid,
  Heatwave,
  Cloudy,
  Fog,
  Overcast,
  Windy,
  IsolatedShowers,
  Rain,
  HeavyShowers,
  Thunderstorms,
  Snow,
  Hail,
  Freezing,
}
export enum PlanetaryEvent {}
export enum DiurnalCycle {
  Day,
  Night,
}
export enum Race {
  Verdani,
  Terrarchs,
  Nebulons,
  Stratosians,
  Cryonics,
  Frostborne,
  Magmarians,
  ForgeLords,
  Aquarians,
  Mareans,
  Solarians,
  DuneWanderers,
  CavernKeepers,
  Echoes,
}
export enum RaceMod {
  Resource,
  Building,
  Research,
  Skill,
}
export enum Civilization {
  Ancient,
  Imperial,
  Industrial,
  Atomic,
  Nexus,
  Galactic,
}
export enum Morale {
  High,
  Neutral,
  Low,
}
export enum Expedition {
  AncientRecruitment,
  HarvestingExpedition,
  ArtifactExploration,
  ImperialRecruitment,
  IndustrialRecruitment,
}
export enum Events {
  Festival,
}
export enum History {
  Created,
  Established,
  Occupied,
  Civilization,
  Building,
}
export enum Resource {
  Food,
  Water,
  Population,
  Energy,
  Ore,
  Science,
  AquaGem,
  ArcaneRelic,
  Biomass,
  CryoCrystal,
  Helium3,
  Magmacite,
  Solarite,
}
/*CITIZEN DATA & EVENT*/
export enum Citizen {
  /*ANCIENT*/
  Plebeian,
  Agricola,
  Craftsmen,
  Warrior,
  /*IMPERIAL*/
  Magister,
  Cocus,
  Excavator,
  Magistrate,
  Merchant,
  Scholar,
  Graduate,
  Artificer,
  Physician,
  Astronomer,
  Priest,
  /*INDUSTIAL*/
  Laborer,
  Farmer,
  Tradesman,
  Soldier,
  Professor,
  Physicist,
  Chemist,
  Geologist,
  /*ATOMIC*/
  Architect,
  Engineer,
  Scientist,
  Commissioner,
  Retailer,
  Miner,
  /*NEXUS*/
  Programmer,
  Astronaut,
  /*GALAXTIC*/
  Trooper,
  CelestialExplorer,
}
export enum Gender {
  Male,
  Female,
}
export enum CitizenTask {
  None,
  Building,
  Upkeep,
  Research,
  Expedition,
}
/*TREES*/
export enum ResearchTree {
  /*ANCIENT*/
  /*IMPERIAL*/
  Professions,
  Religion,
  Astronomy,
  /*INDUSTIAL*/
  CombatTacticts,
  /*ATOMIC*/
  StarCharts,
  NuclearFission,
  /*NEXUS*/
  /*GALAXTIC*/
}
export enum MachineTree {
  /*ANCIENT*/
  /*IMPERIAL*/
  /*INDUSTIAL*/
  /*ATOMIC*/
  /*NEXUS*/
  EarlyWarningSystem,
  Satellite,
  Drone,
  OrbitalMissile,
  /*GALAXTIC*/
  Automaton,
}
export enum VehicleTree {
  /*ANCIENT*/
  /*IMPERIAL*/
  /*INDUSTIAL*/
  /*ATOMIC*/
  /*NEXUS*/
  /*GALAXTIC*/
  Sentinel,
  Pathfinder,
  Skirmisher,
  Dreadnought,
  CargoVessel,
  Ark,
}
/*BUILDINGS*/
export enum R_BuildingCategory {
  Education,
  Excavation,
  Nourishment,
  PowerSource,
  Residence,
  Technology,
}
export enum R_BuildingType {
  /*ANCIENT*/
  /*****************/
  /*FOOD*/
  HuntingAndGathering,
  SubsistenceFarms,
  PastoralFarms,
  /*WATER*/
  NaturalWaterways,
  RainwaterHarvesting,
  /*POPULATION*/
  NomadicTribes,
  Hamlets,
  /*ENERGY*/
  BiofuelBurning,
  /*ORE*/
  SurfaceMining,
  /*SCIENCE*/
  /*****************/
  /*IMPERIAL*/
  /*****************/
  /*FOOD*/
  MixedFarms,
  /*WATER*/
  Wells,
  Cisterns,
  Aqueducts,
  /*POPULATION*/
  Villages,
  ProtoCities,
  /*ENERGY*/
  Windmills,
  Watermills,
  /*ORE*/
  AquaGem,
  ArcaneRelic,
  Biomass,
  CryoCrystal,
  Helium3,
  Magmacite,
  Solarite,
  /*SCIENCE*/
  Schools,
  Universities,
  /*****************/
  /*INDUSTIAL*/
  /*****************/
  /*FOOD*/
  CommercialFarms,
  /*WATER*/
  Reservoirs,
  AquiferAccessPoints,
  /*POPULATION*/
  Cities,
  /*ENERGY*/
  CoalAndSteamPower,
  CrudeOilAndGasEnergy,
  HydroelectricPower,
  /*ORE*/
  SubterraneanMining,
  Quarrying,
  /*SCIENCE*/
  GraduateSchools,
  Factories,
  /*****************/
  /*ATOMIC*/
  /*****************/
  /*FOOD*/
  IndustrialFarms,
  /*WATER*/
  UrbanWaterNetworks,
  /*POPULATION*/
  Metropolises,
  /*ENERGY*/
  NuclearPower,
  /*ORE*/
  /*SCIENCE*/
  /*****************/
  /*NEXUS*/
  /*****************/
  /*FOOD*/
  GMOFarming,
  AquaticFarming,
  /*WATER*/
  DesalinationPlants,
  /*POPULATION*/
  /*ENERGY*/
  SolarPanels,
  WindTurbines,
  GeothermalEnergy,
  /*ORE*/
  TrenchMining,
  ChemicalMining,
  /*SCIENCE*/
  Electronics,
  Computers,
  Cyberspace,
  /*****************/
  /*GALAXTIC*/
  /*****************/
  /*FOOD*/
  VerticalFarming,
  BioEngineeredMeat,
  SyntheticFoodCreation,
  /*WATER*/
  AutomatedWaterNetworks,
  AtmosphericWaterExtractors,
  OrbitalWaterHarvesting,
  /*POPULATION*/
  CyberCities,
  VerticalMetropolises,
  Aquapolis,
  /*ENERGY*/
  FusionPower,
  OrbitalSolarEnergy,
  MarineHydrokineticPower,
  /*ORE*/
  AutonomousMining,
  AsteroidMining,
  /*SCIENCE*/
  ArtificialIntelligence,
}

export const enumToString = <T>(enumType: T, value: T[keyof T]): string => {
  interface EnumMask {
    key: string;
    value: string;
  }
  const enumMasks: EnumMask[] = [
    /**/
    { key: "PowerSrc", value: "Power Source" },
    /*CATEGORY*/
    { key: "PowerSource", value: "Power Source" },
  ];

  const enumString = enumType[value as keyof typeof enumType] as unknown as string;
  const mask = enumMasks.filter((mask) => mask.key === enumString)[0];

  return mask === undefined ? enumString : mask.value;
};
export const stringToEnum = <T>(enumType: T, key: string): T[keyof T] => {
  const e: T[keyof T] = enumType[key as keyof typeof enumType] as T[keyof T];
  return e;
};
