export enum AudioTarget {
  ChooseHomePlanet,
  Generating,
  Theme,
  ButtonHover,
  ButtonClick,
  ButtonClick2,
  Maximize,
  Minimize,
  BuildingBuild,
  BuildingUpgraded,
  BuildingDemolished,
  ExpeditionCompleted,
  ImperialCivilization,
  IndustrialCivilization,
  AtomicCivilization,
  ResearchCompleted,
  UnderConstruction,
  Window,
}

import ChooseHomePlanet from "../assets/audio/choose-home-planet.mp3";
import Generating from "../assets/audio/generating.mp3";
import Theme from "../assets/audio/theme.mp3";
import ButtonHover from "../assets/audio/button-hover.wav";
import ButtonClick from "../assets/audio/button-click.wav";
import ButtonClick2 from "../assets/audio/button-click-2.wav";
import Maximize from "../assets/audio/maximize.wav";
import Minimize from "../assets/audio/minimize.wav";
import BuildingBuild from "../assets/audio/building-build.mp3";
import BuildingUpgraded from "../assets/audio/building-upgraded.mp3";
import BuildingDemolished from "../assets/audio/building-demolished.mp3";
import ExpeditionCompleted from "../assets/audio/expedition-completed.mp3";
import ImperialCivilization from "../assets/audio/imperial-civilization.mp3";
import IndustrialCivilization from "../assets/audio/industrial-civilization.mp3";
import AtomicCivilization from "../assets/audio/atomic-civilization.mp3";
import ResearchCompleted from "../assets/audio/research-completed.mp3";
import UnderConstruction from "../assets/audio/under-construction.mp3";
import Window from "../assets/audio/window.wav";

export const getAudioFile = (target: AudioTarget) => {
  switch (target) {
    case AudioTarget.ChooseHomePlanet:
      return ChooseHomePlanet;
    case AudioTarget.Generating:
      return Generating;
    case AudioTarget.Theme:
      return Theme;
    case AudioTarget.ButtonHover:
      return ButtonHover;
    case AudioTarget.ButtonClick:
      return ButtonClick;
    case AudioTarget.ButtonClick2:
      return ButtonClick2;
    case AudioTarget.Maximize:
      return Maximize;
    case AudioTarget.Minimize:
      return Minimize;
    case AudioTarget.BuildingBuild:
      return BuildingBuild;
    case AudioTarget.BuildingUpgraded:
      return BuildingUpgraded;
    case AudioTarget.BuildingDemolished:
      return BuildingDemolished;
    case AudioTarget.ExpeditionCompleted:
      return ExpeditionCompleted;
    case AudioTarget.ImperialCivilization:
      return ImperialCivilization;
    case AudioTarget.IndustrialCivilization:
      return IndustrialCivilization;
    case AudioTarget.AtomicCivilization:
      return AtomicCivilization;
    case AudioTarget.ResearchCompleted:
      return ResearchCompleted;
    case AudioTarget.UnderConstruction:
      return UnderConstruction;
    case AudioTarget.Window:
      return Window;
    default:
      return "";
  }
};
