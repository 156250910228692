//import { Box, Spinner, Text } from "@chakra-ui/react";

const LoadingFallback = () => {
  /*return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      color="white"
      textAlign="center"
      p={4}
    >
      <Spinner
        size="xl"
        thickness="4px"
        speed="0.65s"
        color="teal.300"
        mb={4}
      />
      <Text fontSize="xl" mb={2}>
        Loading...
      </Text>
      <Text fontSize="md">Please wait while we prepare your empire...</Text>
    </Box>
  );*/
  return <div />;
};

export default LoadingFallback;
