type Struct = {
  master: number;
  music: number;
  sfx: number;
  voice: number;
};

class Volume {
  #volume: Struct;

  constructor() {
    const data = localStorage.getItem("volume");
    if (data === null) {
      this.#volume = {
        master: 1,
        music: 1,
        sfx: 1,
        voice: 1,
      };

      this.#save();
    } else {
      this.#volume = JSON.parse(data);
    }
  }

  #save() {
    localStorage.setItem("volume", JSON.stringify(this.#volume));
  }

  get master() {
    return this.#volume.master;
  }
  set master(value: number) {
    this.#volume.master = value > 1 ? 1 : value < 0 ? 0 : value;
    this.#save();
  }

  get music() {
    return this.#volume.music;
  }
  set music(value: number) {
    this.#volume.music = value > 1 ? 1 : value < 0 ? 0 : value;
    this.#save();
  }

  get sfx() {
    return this.#volume.sfx;
  }
  set sfx(value: number) {
    this.#volume.sfx = value > 1 ? 1 : value < 0 ? 0 : value;
    this.#save();
  }

  get voice() {
    return this.#volume.voice;
  }
  set voice(value: number) {
    this.#volume.voice = value > 1 ? 1 : value < 0 ? 0 : value;
    this.#save();
  }
}

export default Volume;
