import { useRef } from "react";

type Func = (...args: any[]) => void;

const useDebounce = (callback: Func, delay: number): Func => {
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const onCallback: Func = (...args) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      callback(...args);
    }, delay);
  };

  return onCallback;
};

export default useDebounce;
