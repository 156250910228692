import { Citizen, CitizenTask, Civilization, DiurnalCycle, enumToString, Events, Expedition, MachineTree, Morale, Planet, R_BuildingCategory, R_BuildingType, Race, ResearchTree, Resource, VehicleTree, WeatherEvent } from "../structs/Enums";

export type TEncyclopedia = {
  chapterPlanet: PagePlanet[];
  chapterDiurnalCycle: PageDiurnalCycle[];
  chapterWeather: PageWeather[];
  chapterRace: PageRace[];
  chapterCivilization: PageCivilization[];
  chapterMorale: PageMorale[];
  chapterCitizen: PageCitizen[];
  chapterTask: PageTask[];
  chapterResource: PageResource[];
  chapterCategory: PageCategory[];
  chapterBuilding: PageBuilding[];
  chapterExpedition: PageExpedition[];
  chapterEvent: PageEvent[];
  chapterResearchTree: PageResearchTree[];
  chapterMachineTree: PageMachineTree[];
  chapterVehicleTree: PageVehicleTree[];
};
type PagePlanet = {
  type: Planet;
  name_Eng: string;
  description_Eng: string;
};
type PageDiurnalCycle = {
  type: DiurnalCycle;
  name_Eng: string;
  description_Eng: string;
};
type PageWeather = {
  type: WeatherEvent;
  name_Eng: string;
  description_Eng: string;
};
type PageRace = {
  type: Race;
  name_Eng: string;
  singular_Eng: string;
  characteristics_Eng: string;
  abilities_Eng: string;
};
type PageCivilization = {
  type: Civilization;
  name_Eng: string;
  description_Eng: string;
};
type PageMorale = {
  type: Morale;
  name_Eng: string;
  description_Eng: string;
};
type PageCitizen = {
  type: Citizen;
  name_Eng: string;
};
type PageTask = {
  type: CitizenTask;
  name_Eng: string;
};
type PageResource = {
  type: Resource;
  name_Eng: string;
};
type PageCategory = {
  type: R_BuildingCategory;
  name_Eng: string;
};
type PageBuilding = {
  type: R_BuildingType;
  name_Eng: string;
  description_Eng: string;
};
type PageExpedition = {
  type: Expedition;
  name_Eng: string;
  description_Eng: string;
};
type PageEvent = {
  type: Events;
  name_Eng: string;
  description_Eng: string;
  diurnalcycle_Eng: string;
};
type PageResearchTree = {
  type: ResearchTree;
  name_Eng: string;
  description_Eng: string;
  weather_Eng: string;
};
type PageMachineTree = {
  type: MachineTree;
  name_Eng: string;
  description_Eng: string;
};
type PageVehicleTree = {
  type: VehicleTree;
  name_Eng: string;
  description_Eng: string;
};

export enum Chapter {
  Email,
  Planet,
  DiurnalCycle,
  Weather,
  Race,
  Civilization,
  Morale,
  Citizen,
  Task,
  Resource,
  Category,
  Building,
  Expedition,
  Event,
  ResearchTree,
  MachineTree,
  VehicleTree,
}
export enum Key {
  Name,
  Description,
  Singular,
  Plural,
  Characteristics,
  Abilities,
  DiurnalCycle,
  Weather,
}
type typeOfEnum = Planet | DiurnalCycle | WeatherEvent | Race | Civilization | Morale | Citizen | CitizenTask | Resource | R_BuildingCategory | R_BuildingType | Expedition | Events | ResearchTree | MachineTree | VehicleTree;
type typeOfPage =
  | PagePlanet
  | PageDiurnalCycle
  | PageWeather
  | PageRace
  | PageCivilization
  | PageMorale
  | PageCitizen
  | PageTask
  | PageResource
  | PageCategory
  | PageBuilding
  | PageExpedition
  | PageEvent
  | PageResearchTree
  | PageMachineTree
  | PageVehicleTree;

class Encyclopedia {
  lang: string;

  constructor() {
    this.lang = "Eng";
  }
  #get = () => {
    const json = localStorage.getItem("encyclopedia");
    if (json == null) return null;

    const encyclopedia: TEncyclopedia = JSON.parse(json);
    return encyclopedia;
  };
  #key = (key: Key) => {
    const value = enumToString(Key, key).toLowerCase();
    switch (key) {
      default:
        return `${value}_${this.lang}`;
    }
  };
  set = (encyclopedia: TEncyclopedia): void => {
    localStorage.setItem("encyclopedia", JSON.stringify(encyclopedia));
  };
  page = (chapter: Chapter, type: typeOfEnum) => {
    const encyclopedia = this.#get();
    let page: typeOfPage | undefined = undefined;
    switch (chapter) {
      case Chapter.Planet:
        page = encyclopedia?.chapterPlanet.find((page) => page.type === type);
        break;
      case Chapter.DiurnalCycle:
        page = encyclopedia?.chapterDiurnalCycle.find((page) => page.type === type);
        break;
      case Chapter.Weather:
        page = encyclopedia?.chapterWeather.find((page) => page.type === type);
        break;
      case Chapter.Race:
        page = encyclopedia?.chapterRace.find((page) => page.type === type);
        break;
      case Chapter.Civilization:
        page = encyclopedia?.chapterCivilization.find((page) => page.type === type);
        break;
      case Chapter.Morale:
        page = encyclopedia?.chapterMorale.find((page) => page.type === type);
        break;
      case Chapter.Citizen:
        page = encyclopedia?.chapterCitizen.find((page) => page.type === type);
        break;
      case Chapter.Task:
        page = encyclopedia?.chapterTask.find((page) => page.type === type);
        break;
      case Chapter.Resource:
        page = encyclopedia?.chapterResource.find((page) => page.type === type);
        break;
      case Chapter.Category:
        page = encyclopedia?.chapterCategory.find((page) => page.type === type);
        break;
      case Chapter.Building:
        page = encyclopedia?.chapterBuilding.find((page) => page.type === type);
        break;
      case Chapter.Expedition:
        page = encyclopedia?.chapterExpedition.find((page) => page.type === type);
        break;
      case Chapter.Event:
        page = encyclopedia?.chapterEvent.find((page) => page.type === type);
        break;
      case Chapter.ResearchTree:
        page = encyclopedia?.chapterResearchTree.find((page) => page.type === type);
        break;
      case Chapter.MachineTree:
        page = encyclopedia?.chapterMachineTree.find((page) => page.type === type);
        break;
      case Chapter.VehicleTree:
        page = encyclopedia?.chapterVehicleTree.find((page) => page.type === type);
        break;
      default:
        break;
    }

    return page;
  };
  word = (chapter: Chapter, type: typeOfEnum, key: Key) => {
    const page = this.page(chapter, type);

    return page ? (page[this.#key(key) as keyof typeof page] as string) : "";
  };
}

export default Encyclopedia;
