// theme.ts
import { extendTheme, ThemeConfig } from "@chakra-ui/react";

const colors = {
  space: {
    50: "#e4e4eb", // lightest shade for detailed highlights or borders
    100: "#b1b1c0", // slightly darker for disabled states or less prominent elements
    200: "#7d7d95", // mid-light for secondary information
    300: "#4a4a6a", // darker still, for tertiary structures
    400: "#2e2e50", // updated to include a hint of deep indigo for more depth
    500: "#101027", // primary UI backgrounds
    600: "#0d0c2f", // updated with a hint of blue for active states or hover backgrounds
    700: "#0a0817", // deep shadow effects or text backgrounds
    800: "#07040f", // very deep for overlays or modals
    900: "#040207", // almost black for maximum contrast in text or critical UI elements
  },
  accent: {
    500: "#32CD32", // Lime Green for a bright, vibrant look
    600: "#2E8B57", // Slightly darker green for hover effects (Sage Green)
    700: "#228B22", // Even deeper green for active states (Forest Green)
  },
  metallic: {
    500: "#cacccd", // Light metallic gray
    600: "#b1b3b5", // Medium metallic gray
    700: "#989a9c", // Dark metallic gray
    800: "#7f8183", // Darker shade for active states
  },
  tech: {
    500: "#8aedc9",
    600: "#69e4b1",
    700: "#47da9a",
    800: "#2ad082",
  },
  neon: {
    500: "#cf13fb", // Neon magenta for special buttons or highlights
  },
  buildingUpgrade: "#ffbf00",
  buildingOffline: "#D32F2F",
};

const config: ThemeConfig = {
  initialColorMode: "dark",
  useSystemColorMode: false,
};

const components = {
  Button: {
    baseStyle: {
      fontWeight: "bold",
      textTransform: "none",
      borderRadius: "6px",
      letterSpacing: "normal",
      transition: "all 0.2s ease",
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
    },
    variants: {
      solid: {
        bg: "#18252c",
        color: "#52b4b0",
        _hover: {
          bg: "#2f3a41",
          color: "#63bbb7",
        },
      },
    },
  },
  Input: {
    variants: {
      outline: {
        field: {
          borderColor: "accent.500",
          _hover: {
            borderColor: "accent.700",
          },
          _focus: {
            borderColor: "accent.500",
            boxShadow: "0 0 0 1px #0099ff",
          },
        },
      },
      filled: {
        field: {
          bg: "space.700",
          _focus: {
            bg: "space.700",
            borderColor: "accent.500",
          },
        },
      },
    },
  },
  Text: {
    baseStyle: {
      color: "space.50",
      fontWeight: "normal",
    },
  },
  Link: {
    baseStyle: {
      color: "accent.500",
      _hover: {
        textDecoration: "underline",
      },
    },
  },
  Modal: {
    baseStyle: {
      dialog: {
        bg: "space.700",
      },
    },
  },
  Tooltip: {
    baseStyle: {
      bg: "space.500",
      color: "space.50",
    },
  },
  Heading: {
    baseStyle: {
      fontWeight: "bold",
      color: "accent.500",
      textShadow: "0px 0px 10px rgba(0, 153, 255, 0.8)",
    },
  },
};

const fonts = {
  heading: "'Exo 2', sans-serif",
  body: "'Roboto', sans-serif",
};

const breakpoints = {
  sm: "30em",
  md: "48em",
  lg: "62em",
  xl: "80em",
  "2xl": "90em",
};

const themeCUI = extendTheme({ config, colors, components, fonts, breakpoints });
export default themeCUI;
