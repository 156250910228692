import { Button, ResponsiveValue } from "@chakra-ui/react";
import { AudioTarget } from "../../services/audio";
import useAudioSFX from "../../services/audio/useAudioSFX";
import { ReactNode } from "react";

type Props = {
  children?: ReactNode;
  size?: ResponsiveValue<string> | undefined;
  isDisabled?: boolean | undefined;
  onClick: () => void;
};

const GameButton = ({ children, size, isDisabled, onClick }: Props) => {
  const { play: bh_play, playing: bh_playing } = useAudioSFX(AudioTarget.ButtonHover);
  const { play: bc_play } = useAudioSFX(AudioTarget.ButtonClick2);

  return (
    <Button
      onMouseEnter={() => {
        if (!bh_playing) bh_play();
      }}
      onClick={() => {
        bc_play();
        onClick();
      }}
      textTransform="uppercase"
      size={size}
      variant="solid"
      isDisabled={isDisabled}
    >
      {children}
    </Button>
  );
};

export default GameButton;
