import {online} from './internet';

interface ISettings {
	url: string;
	token: string;
	content: FormData | Record<string, any>;
	headers: Record<string, string>;
	post: boolean;
}
interface IResponse {
	ok: boolean;
	status?: number;
	data?: any;
}
interface IContent {
	content: Record<string, string> | string;
	timestamp: string;
}

const api = (settings: ISettings): Promise<IResponse> => {
	return new Promise<IResponse>(async (resolve) => {
		if (!online()) return resolve({ok: false});

		try {
			const method: 'POST' | 'GET' = settings.post ? 'POST' : 'GET';
			const headers = {
				'Access-Control-Allow-Origin': '*',
				token: settings.token,
				...(settings.content instanceof FormData
					? {}
					: {'Content-Type': 'application/json'}),
				...settings.headers,
			};

			const body =
				settings.content instanceof FormData
					? settings.content
					: JSON.stringify(settings.content);

			const response = await fetch(
				settings.url,
				settings.post ? {method, headers, body} : {method, headers}
			);

			const data = await response.json();
			return resolve({
				ok: response.ok,
				status: response.status,
				data: data as IContent,
			});
		} catch (e) {
			return resolve({ok: false});
		}
	});
};

export default api;
