import { create } from "zustand";
import { subscribeWithSelector } from "zustand/middleware";
import { ResearchDTO } from "../../structs/dtos/Empire";

type StoreResearchTree = {
  professions: ResearchDTO | undefined;
  setProfessions: (professions: ResearchDTO) => void;
  religion: ResearchDTO | undefined;
  setReligion: (religion: ResearchDTO) => void;
  astronomy: ResearchDTO | undefined;
  setAstronomy: (religion: ResearchDTO) => void;
  combatTacticts: ResearchDTO | undefined;
  setCombatTacticts: (combatTacticts: ResearchDTO) => void;
  starCharts: ResearchDTO | undefined;
  setStarCharts: (starCharts: ResearchDTO) => void;
  nuclearFission: ResearchDTO | undefined;
  setNuclearFission: (nuclearFission: ResearchDTO) => void;
};

const useStoreResearchTree = create<StoreResearchTree, [["zustand/subscribeWithSelector", never]]>(
  subscribeWithSelector<StoreResearchTree>((set) => ({
    professions: undefined,
    setProfessions: (professions) => set({ professions }),
    religion: undefined,
    setReligion: (religion) => set({ religion }),
    astronomy: undefined,
    setAstronomy: (astronomy) => set({ astronomy }),
    combatTacticts: undefined,
    setCombatTacticts: (combatTacticts) => set({ combatTacticts }),
    starCharts: undefined,
    setStarCharts: (starCharts) => set({ starCharts }),
    nuclearFission: undefined,
    setNuclearFission: (nuclearFission) => set({ nuclearFission }),
  }))
);
export default useStoreResearchTree;
