export enum ImageType {
  Raw,
  Preview,
  Background,
}

import placeholder from "../assets/placeholder.webp";
/*SPLASH*/
import landing from "../assets/splash/landing.webp";
import creating from "../assets/splash/creating.webp";
/**/
import app from "../assets/maskable_icon.png";
/*PLANET*/
import BG_Terran from "../assets/backgrounds/Terran.png";
import BG_GasGiant from "../assets/backgrounds/GasGiant.png";
import BG_Frozen from "../assets/backgrounds/Frozen.png";
import BG_Lava from "../assets/backgrounds/Lava.png";
import BG_Oceanic from "../assets/backgrounds/Oceanic.png";
import BG_Desert from "../assets/backgrounds/Desert.png";
import BG_Hollow from "../assets/backgrounds/Hollow.png";

import Terran from "../assets/planets/Terran.png";
import GasGiant from "../assets/planets/GasGiant.png";
import Frozen from "../assets/planets/Frozen.png";
import Lava from "../assets/planets/Lava.png";
import Oceanic from "../assets/planets/Oceanic.png";
import Desert from "../assets/planets/Desert.png";
import Hollow from "../assets/planets/Hollow.png";
/*DIURNAL CYCLE*/
import Day from "../assets/diurnalCycle/Day.png";
import Night from "../assets/diurnalCycle/Night.png";
/*WEATHER*/
import ClearSkies from "../assets/weather/ClearSkies.png";
import Humid from "../assets/weather/Humid.png";
import Heatwave from "../assets/weather/Heatwave.png";
import Cloudy from "../assets/weather/Cloudy.png";
import Fog from "../assets/weather/Fog.png";
import Overcast from "../assets/weather/Overcast.png";
import Windy from "../assets/weather/Windy.png";
import Rain from "../assets/weather/Rain.png";
import IsolatedShowers from "../assets/weather/IsolatedShowers.png";
import HeavyShowers from "../assets/weather/HeavyShowers.png";
import Thunderstorms from "../assets/weather/Thunderstorms.png";
import Snow from "../assets/weather/Snow.png";
import Hail from "../assets/weather/Hail.png";
import Freezing from "../assets/weather/Freezing.png";
/*RACES*/
import Verdani from "../assets/races/Verdani.png";
import Terrarchs from "../assets/races/Terrarchs.png";
import Nebulons from "../assets/races/Nebulons.png";
import Stratosians from "../assets/races/Stratosians.png";
import Cryonics from "../assets/races/Cryonics.png";
import Frostborne from "../assets/races/Frostborne.png";
import Magmarians from "../assets/races/Magmarians.png";
import ForgeLords from "../assets/races/ForgeLords.png";
import Aquarians from "../assets/races/Aquarians.png";
import Mareans from "../assets/races/Mareans.png";
import Solarians from "../assets/races/Solarians.png";
import DuneWanderers from "../assets/races/DuneWanderers.png";
import CavernKeepers from "../assets/races/CavernKeepers.png";
import Echoes from "../assets/races/Echoes.png";
/*MORALE*/
import High from "../assets/morale/High.png";
import Neutral from "../assets/morale/Neutral.png";
import Low from "../assets/morale/Low.png";
/*MENU*/
import Home from "../assets/menu/Home.png";
import Civilization from "../assets/menu/Civilization.png";
import Citizens from "../assets/menu/Citizens.png";
import Nourishment from "../assets/menu/Nourishment.png";
import Residence from "../assets/menu/Residence.png";
import PowerSource from "../assets/menu/PowerSource.png";
import Excavation from "../assets/menu/Excavation.png";
import Education from "../assets/menu/Education.png";
import Technology from "../assets/menu/Technology.png";
import Research from "../assets/menu/Research.png";
import Manufacturing from "../assets/menu/Manufacturing.png";
import Hangar from "../assets/menu/Hangar.png";
import OSOS from "../assets/menu/OSOS.png";
import Expedition from "../assets/menu/Expedition.png";
import Events from "../assets/menu/Events.png";
/*RESOURCE*/
import Food from "../assets/resources/Food.png";
import Water from "../assets/resources/Water.png";
import Population from "../assets/resources/Population.png";
import Energy from "../assets/resources/Energy.png";
import Ore from "../assets/resources/Ore.png";
import Science from "../assets/resources/Science.png";
import AquaGem from "../assets/resources/AquaGem.png";
import ArcaneRelic from "../assets/resources/ArcaneRelic.png";
import Biomass from "../assets/resources/Biomass.png";
import CryoCrystal from "../assets/resources/CryoCrystal.png";
import Helium3 from "../assets/resources/Helium3.png";
import Magmacite from "../assets/resources/Magmacite.png";
import Solarite from "../assets/resources/Solarite.png";
/*BUILDINGS*/
/*ANCIENT*/
/*****************/
/*FOOD*/
import Ter_HuntingAndGathering from "../assets/buildings/Terran//Ancient/HuntingAndGathering.png";
import Gas_HuntingAndGathering from "../assets/buildings/GasGiant/Ancient/HuntingAndGathering.png";
import Fro_HuntingAndGathering from "../assets/buildings/Frozen/Ancient/HuntingAndGathering.png";
import Lav_HuntingAndGathering from "../assets/buildings/Lava/Ancient/HuntingAndGathering.png";
import Oce_HuntingAndGathering from "../assets/buildings/Oceanic/Ancient/HuntingAndGathering.png";
import Des_HuntingAndGathering from "../assets/buildings/Desert/Ancient/HuntingAndGathering.png";
import Hol_HuntingAndGathering from "../assets/buildings/Hollow/Ancient/HuntingAndGathering.png";
import Ter_SubsistenceFarms from "../assets/buildings/Terran/Ancient/SubsistenceFarms.png";
import Gas_SubsistenceFarms from "../assets/buildings/GasGiant/Ancient/SubsistenceFarms.png";
import Fro_SubsistenceFarms from "../assets/buildings/Frozen/Ancient/SubsistenceFarms.png";
import Lav_SubsistenceFarms from "../assets/buildings/Lava/Ancient/SubsistenceFarms.png";
import Oce_SubsistenceFarms from "../assets/buildings/Oceanic/Ancient/SubsistenceFarms.png";
import Des_SubsistenceFarms from "../assets/buildings/Desert/Ancient/SubsistenceFarms.png";
import Hol_SubsistenceFarms from "../assets/buildings/Hollow/Ancient/SubsistenceFarms.png";
import Ter_PastoralFarms from "../assets/buildings/Terran/Ancient/PastoralFarms.png";
import Gas_PastoralFarms from "../assets/buildings/GasGiant/Ancient/PastoralFarms.png";
import Fro_PastoralFarms from "../assets/buildings/Frozen/Ancient/PastoralFarms.png";
import Lav_PastoralFarms from "../assets/buildings/Lava/Ancient/PastoralFarms.png";
import Oce_PastoralFarms from "../assets/buildings/Oceanic/Ancient/PastoralFarms.png";
import Des_PastoralFarms from "../assets/buildings/Desert/Ancient/PastoralFarms.png";
import Hol_PastoralFarms from "../assets/buildings/Hollow/Ancient/PastoralFarms.png";
/*WATER*/
import Ter_NaturalWaterways from "../assets/buildings/Terran/Ancient/NaturalWaterways.png";
import Gas_NaturalWaterways from "../assets/buildings/GasGiant/Ancient/NaturalWaterways.png";
import Fro_NaturalWaterways from "../assets/buildings/Frozen/Ancient/NaturalWaterways.png";
import Lav_NaturalWaterways from "../assets/buildings/Lava/Ancient/NaturalWaterways.png";
import Oce_NaturalWaterways from "../assets/buildings/Oceanic/Ancient/NaturalWaterways.png";
import Des_NaturalWaterways from "../assets/buildings/Desert/Ancient/NaturalWaterways.png";
import Hol_NaturalWaterways from "../assets/buildings/Hollow/Ancient/NaturalWaterways.png";
import Ter_RainwaterHarvesting from "../assets/buildings/Terran/Ancient/RainwaterHarvesting.png";
import Gas_RainwaterHarvesting from "../assets/buildings/GasGiant/Ancient/RainwaterHarvesting.png";
import Fro_RainwaterHarvesting from "../assets/buildings/Frozen/Ancient/RainwaterHarvesting.png";
import Lav_RainwaterHarvesting from "../assets/buildings/Lava/Ancient/RainwaterHarvesting.png";
import Oce_RainwaterHarvesting from "../assets/buildings/Oceanic/Ancient/RainwaterHarvesting.png";
import Des_RainwaterHarvesting from "../assets/buildings/Desert/Ancient/RainwaterHarvesting.png";
import Hol_RainwaterHarvesting from "../assets/buildings/Hollow/Ancient/RainwaterHarvesting.png";
/*POPULATION*/
import Ter_NomadicTribes from "../assets/buildings/Terran/Ancient/NomadicTribes.png";
import Gas_NomadicTribes from "../assets/buildings/GasGiant/Ancient/NomadicTribes.png";
import Fro_NomadicTribes from "../assets/buildings/Frozen/Ancient/NomadicTribes.png";
import Lav_NomadicTribes from "../assets/buildings/Lava/Ancient/NomadicTribes.png";
import Oce_NomadicTribes from "../assets/buildings/Oceanic/Ancient/NomadicTribes.png";
import Des_NomadicTribes from "../assets/buildings/Desert/Ancient/NomadicTribes.png";
import Hol_NomadicTribes from "../assets/buildings/Hollow/Ancient/NomadicTribes.png";
import Ter_Hamlets from "../assets/buildings/Terran/Ancient/Hamlets.png";
import Gas_Hamlets from "../assets/buildings/GasGiant/Ancient/Hamlets.png";
import Fro_Hamlets from "../assets/buildings/Frozen/Ancient/Hamlets.png";
import Lav_Hamlets from "../assets/buildings/Lava/Ancient/Hamlets.png";
import Oce_Hamlets from "../assets/buildings/Oceanic/Ancient/Hamlets.png";
import Des_Hamlets from "../assets/buildings/Desert/Ancient/Hamlets.png";
import Hol_Hamlets from "../assets/buildings/Hollow/Ancient/Hamlets.png";
/*ENERGY*/
import Ter_BiofuelBurning from "../assets/buildings/Terran/Ancient/BiofuelBurning.png";
import Gas_BiofuelBurning from "../assets/buildings/GasGiant/Ancient/BiofuelBurning.png";
import Fro_BiofuelBurning from "../assets/buildings/Frozen/Ancient/BiofuelBurning.png";
import Lav_BiofuelBurning from "../assets/buildings/Lava/Ancient/BiofuelBurning.png";
import Oce_BiofuelBurning from "../assets/buildings/Oceanic/Ancient/BiofuelBurning.png";
import Des_BiofuelBurning from "../assets/buildings/Desert/Ancient/BiofuelBurning.png";
import Hol_BiofuelBurning from "../assets/buildings/Hollow/Ancient/BiofuelBurning.png";
/*ORE*/
import Ter_SurfaceMining from "../assets/buildings/Terran/Ancient/SurfaceMining.png";
import Gas_SurfaceMining from "../assets/buildings/GasGiant/Ancient/SurfaceMining.png";
import Fro_SurfaceMining from "../assets/buildings/Frozen/Ancient/SurfaceMining.png";
import Lav_SurfaceMining from "../assets/buildings/Lava/Ancient/SurfaceMining.png";
import Oce_SurfaceMining from "../assets/buildings/Oceanic/Ancient/SurfaceMining.png";
import Des_SurfaceMining from "../assets/buildings/Desert/Ancient/SurfaceMining.png";
import Hol_SurfaceMining from "../assets/buildings/Hollow/Ancient/SurfaceMining.png";
/*SCIENCE*/
/*****************/
/*IMPERIAL*/
/*****************/
/*FOOD*/
import Ter_MixedFarms from "../assets/buildings/Terran/Imperial/MixedFarms.png";
import Gas_MixedFarms from "../assets/buildings/GasGiant/Imperial/MixedFarms.png";
import Fro_MixedFarms from "../assets/buildings/Frozen/Imperial/MixedFarms.png";
import Lav_MixedFarms from "../assets/buildings/Lava/Imperial/MixedFarms.png";
import Oce_MixedFarms from "../assets/buildings/Oceanic/Imperial/MixedFarms.png";
import Des_MixedFarms from "../assets/buildings/Desert/Imperial/MixedFarms.png";
import Hol_MixedFarms from "../assets/buildings/Hollow/Imperial/MixedFarms.png";
/*WATER*/
import Ter_Wells from "../assets/buildings/Terran/Imperial/Wells.png";
import Gas_Wells from "../assets/buildings/GasGiant/Imperial/Wells.png";
import Fro_Wells from "../assets/buildings/Frozen/Imperial/Wells.png";
import Lav_Wells from "../assets/buildings/Lava/Imperial/Wells.png";
import Oce_Wells from "../assets/buildings/Oceanic/Imperial/Wells.png";
import Des_Wells from "../assets/buildings/Desert/Imperial/Wells.png";
import Hol_Wells from "../assets/buildings/Hollow/Imperial/Wells.png";
import Ter_Cisterns from "../assets/buildings/Terran/Imperial/Cisterns.png";
import Gas_Cisterns from "../assets/buildings/GasGiant/Imperial/Cisterns.png";
import Fro_Cisterns from "../assets/buildings/Frozen/Imperial/Cisterns.png";
import Lav_Cisterns from "../assets/buildings/Lava/Imperial/Cisterns.png";
import Oce_Cisterns from "../assets/buildings/Oceanic/Imperial/Cisterns.png";
import Des_Cisterns from "../assets/buildings/Desert/Imperial/Cisterns.png";
import Hol_Cisterns from "../assets/buildings/Hollow/Imperial/Cisterns.png";
import Ter_Aqueducts from "../assets/buildings/Terran/Imperial/Aqueducts.png";
import Gas_Aqueducts from "../assets/buildings/GasGiant/Imperial/Aqueducts.png";
import Fro_Aqueducts from "../assets/buildings/Frozen/Imperial/Aqueducts.png";
import Lav_Aqueducts from "../assets/buildings/Lava/Imperial/Aqueducts.png";
import Oce_Aqueducts from "../assets/buildings/Oceanic/Imperial/Aqueducts.png";
import Des_Aqueducts from "../assets/buildings/Desert/Imperial/Aqueducts.png";
import Hol_Aqueducts from "../assets/buildings/Hollow/Imperial/Aqueducts.png";
/*POPULATION*/
import Ter_Villages from "../assets/buildings/Terran/Imperial/Villages.png";
import Gas_Villages from "../assets/buildings/GasGiant/Imperial/Villages.png";
import Fro_Villages from "../assets/buildings/Frozen/Imperial/Villages.png";
import Lav_Villages from "../assets/buildings/Lava/Imperial/Villages.png";
import Oce_Villages from "../assets/buildings/Oceanic/Imperial/Villages.png";
import Des_Villages from "../assets/buildings/Desert/Imperial/Villages.png";
import Hol_Villages from "../assets/buildings/Hollow/Imperial/Villages.png";
import Ter_ProtoCities from "../assets/buildings/Terran/Imperial/ProtoCities.png";
import Gas_ProtoCities from "../assets/buildings/GasGiant/Imperial/ProtoCities.png";
import Fro_ProtoCities from "../assets/buildings/Frozen/Imperial/ProtoCities.png";
import Lav_ProtoCities from "../assets/buildings/Lava/Imperial/ProtoCities.png";
import Oce_ProtoCities from "../assets/buildings/Oceanic/Imperial/ProtoCities.png";
import Des_ProtoCities from "../assets/buildings/Desert/Imperial/ProtoCities.png";
import Hol_ProtoCities from "../assets/buildings/Hollow/Imperial/ProtoCities.png";
/*ENERGY*/
import Ter_Windmills from "../assets/buildings/Terran/Imperial/Windmills.png";
import Gas_Windmills from "../assets/buildings/GasGiant/Imperial/Windmills.png";
import Fro_Windmills from "../assets/buildings/Frozen/Imperial/Windmills.png";
import Lav_Windmills from "../assets/buildings/Lava/Imperial/Windmills.png";
import Oce_Windmills from "../assets/buildings/Oceanic/Imperial/Windmills.png";
import Des_Windmills from "../assets/buildings/Desert/Imperial/Windmills.png";
import Hol_Windmills from "../assets/buildings/Hollow/Imperial/Windmills.png";
import Ter_Watermills from "../assets/buildings/Terran/Imperial/Watermills.png";
import Gas_Watermills from "../assets/buildings/GasGiant/Imperial/Watermills.png";
import Fro_Watermills from "../assets/buildings/Frozen/Imperial/Watermills.png";
import Lav_Watermills from "../assets/buildings/Lava/Imperial/Watermills.png";
import Oce_Watermills from "../assets/buildings/Oceanic/Imperial/Watermills.png";
import Des_Watermills from "../assets/buildings/Desert/Imperial/Watermills.png";
import Hol_Watermills from "../assets/buildings/Hollow/Imperial/Watermills.png";
/*ORE*/
import Ter_Biomass from "../assets/buildings/Terran/Imperial/Biomass.png";
import Gas_Helium3 from "../assets/buildings/GasGiant/Imperial/Helium3.png";
import Fro_CryoCrystal from "../assets/buildings/Frozen/Imperial/CryoCrystal.png";
import Lav_Magmacite from "../assets/buildings/Lava/Imperial/Magmacite.png";
import Oce_AquaGem from "../assets/buildings/Oceanic/Imperial/AquaGem.png";
import Des_Solarite from "../assets/buildings/Desert/Imperial/Solarite.png";
import Hol_ArcaneRelic from "../assets/buildings/Hollow/Imperial/ArcaneRelic.png";
/*SCIENCE*/
import Ter_Schools from "../assets/buildings/Terran/Imperial/Schools.png";
import Gas_Schools from "../assets/buildings/GasGiant/Imperial/Schools.png";
import Fro_Schools from "../assets/buildings/Frozen/Imperial/Schools.png";
import Lav_Schools from "../assets/buildings/Lava/Imperial/Schools.png";
import Oce_Schools from "../assets/buildings/Oceanic/Imperial/Schools.png";
import Des_Schools from "../assets/buildings/Desert/Imperial/Schools.png";
import Hol_Schools from "../assets/buildings/Hollow/Imperial/Schools.png";
import Ter_Universities from "../assets/buildings/Terran/Imperial/Universities.png";
import Gas_Universities from "../assets/buildings/GasGiant/Imperial/Universities.png";
import Fro_Universities from "../assets/buildings/Frozen/Imperial/Universities.png";
import Lav_Universities from "../assets/buildings/Lava/Imperial/Universities.png";
import Oce_Universities from "../assets/buildings/Oceanic/Imperial/Universities.png";
import Des_Universities from "../assets/buildings/Desert/Imperial/Universities.png";
import Hol_Universities from "../assets/buildings/Hollow/Imperial/Universities.png";
/*****************/
/*INDUSTIAL*/
/*****************/
/*FOOD*/
import Ter_CommercialFarms from "../assets/buildings/Terran/Industrial/CommercialFarms.png";
import Gas_CommercialFarms from "../assets/buildings/GasGiant/Industrial/CommercialFarms.png";
import Fro_CommercialFarms from "../assets/buildings/Frozen/Industrial/CommercialFarms.png";
import Lav_CommercialFarms from "../assets/buildings/Lava/Industrial/CommercialFarms.png";
import Oce_CommercialFarms from "../assets/buildings/Oceanic/Industrial/CommercialFarms.png";
import Des_CommercialFarms from "../assets/buildings/Desert/Industrial/CommercialFarms.png";
import Hol_CommercialFarms from "../assets/buildings/Hollow/Industrial/CommercialFarms.png";
/*WATER*/
import Ter_Reservoirs from "../assets/buildings/Terran/Industrial/Reservoirs.png";
import Gas_Reservoirs from "../assets/buildings/GasGiant/Industrial/Reservoirs.png";
import Fro_Reservoirs from "../assets/buildings/Frozen/Industrial/Reservoirs.png";
import Lav_Reservoirs from "../assets/buildings/Lava/Industrial/Reservoirs.png";
import Oce_Reservoirs from "../assets/buildings/Oceanic/Industrial/Reservoirs.png";
import Des_Reservoirs from "../assets/buildings/Desert/Industrial/Reservoirs.png";
import Hol_Reservoirs from "../assets/buildings/Hollow/Industrial/Reservoirs.png";
import Ter_AquiferAccessPoints from "../assets/buildings/Terran/Industrial/AquiferAccessPoints.png";
import Gas_AquiferAccessPoints from "../assets/buildings/GasGiant/Industrial/AquiferAccessPoints.png";
import Fro_AquiferAccessPoints from "../assets/buildings/Frozen/Industrial/AquiferAccessPoints.png";
import Lav_AquiferAccessPoints from "../assets/buildings/Lava/Industrial/AquiferAccessPoints.png";
import Oce_AquiferAccessPoints from "../assets/buildings/Oceanic/Industrial/AquiferAccessPoints.png";
import Des_AquiferAccessPoints from "../assets/buildings/Desert/Industrial/AquiferAccessPoints.png";
import Hol_AquiferAccessPoints from "../assets/buildings/Hollow/Industrial/AquiferAccessPoints.png";
/*POPULATION*/
import Ter_Cities from "../assets/buildings/Terran/Industrial/Cities.png";
import Gas_Cities from "../assets/buildings/GasGiant/Industrial/Cities.png";
import Fro_Cities from "../assets/buildings/Frozen/Industrial/Cities.png";
import Lav_Cities from "../assets/buildings/Lava/Industrial/Cities.png";
import Oce_Cities from "../assets/buildings/Oceanic/Industrial/Cities.png";
import Des_Cities from "../assets/buildings/Desert/Industrial/Cities.png";
import Hol_Cities from "../assets/buildings/Hollow/Industrial/Cities.png";
/*ENERGY*/
import Ter_CrudeOilAndGasEnergy from "../assets/buildings/Terran/Industrial/CrudeOilAndGasEnergy.png";
import Gas_CrudeOilAndGasEnergy from "../assets/buildings/GasGiant/Industrial/CrudeOilAndGasEnergy.png";
import Fro_CrudeOilAndGasEnergy from "../assets/buildings/Frozen/Industrial/CrudeOilAndGasEnergy.png";
import Lav_CrudeOilAndGasEnergy from "../assets/buildings/Lava/Industrial/CrudeOilAndGasEnergy.png";
import Oce_CrudeOilAndGasEnergy from "../assets/buildings/Oceanic/Industrial/CrudeOilAndGasEnergy.png";
import Des_CrudeOilAndGasEnergy from "../assets/buildings/Desert/Industrial/CrudeOilAndGasEnergy.png";
import Hol_CrudeOilAndGasEnergy from "../assets/buildings/Hollow/Industrial/CrudeOilAndGasEnergy.png";
/*ORE*/
import Ter_Quarrying from "../assets/buildings/Terran/Industrial/Quarrying.png";
import Gas_Quarrying from "../assets/buildings/GasGiant/Industrial/Quarrying.png";
import Fro_Quarrying from "../assets/buildings/Frozen/Industrial/Quarrying.png";
import Lav_Quarrying from "../assets/buildings/Lava/Industrial/Quarrying.png";
import Oce_Quarrying from "../assets/buildings/Oceanic/Industrial/Quarrying.png";
import Des_Quarrying from "../assets/buildings/Desert/Industrial/Quarrying.png";
import Hol_Quarrying from "../assets/buildings/Hollow/Industrial/Quarrying.png";
/*SCIENCE*/
import Ter_GraduateSchools from "../assets/buildings/Terran/Industrial/GraduateSchools.png";
import Gas_GraduateSchools from "../assets/buildings/GasGiant/Industrial/GraduateSchools.png";
import Fro_GraduateSchools from "../assets/buildings/Frozen/Industrial/GraduateSchools.png";
import Lav_GraduateSchools from "../assets/buildings/Lava/Industrial/GraduateSchools.png";
import Oce_GraduateSchools from "../assets/buildings/Oceanic/Industrial/GraduateSchools.png";
import Des_GraduateSchools from "../assets/buildings/Desert/Industrial/GraduateSchools.png";
import Hol_GraduateSchools from "../assets/buildings/Hollow/Industrial/GraduateSchools.png";
import Ter_Factories from "../assets/buildings/Terran/Industrial/Factories.png";
import Gas_Factories from "../assets/buildings/GasGiant/Industrial/Factories.png";
import Fro_Factories from "../assets/buildings/Frozen/Industrial/Factories.png";
import Lav_Factories from "../assets/buildings/Lava/Industrial/Factories.png";
import Oce_Factories from "../assets/buildings/Oceanic/Industrial/Factories.png";
import Des_Factories from "../assets/buildings/Desert/Industrial/Factories.png";
import Hol_Factories from "../assets/buildings/Hollow/Industrial/Factories.png";
/*****************/
/*ATOMIC*/
/*****************/
/*FOOD*/
import Ter_IndustrialFarms from "../assets/buildings/Terran/Atomic/IndustrialFarms.png";
import Gas_IndustrialFarms from "../assets/buildings/GasGiant/Atomic/IndustrialFarms.png";
import Fro_IndustrialFarms from "../assets/buildings/Frozen/Atomic/IndustrialFarms.png";
import Lav_IndustrialFarms from "../assets/buildings/Lava/Atomic/IndustrialFarms.png";
import Oce_IndustrialFarms from "../assets/buildings/Oceanic/Atomic/IndustrialFarms.png";
import Des_IndustrialFarms from "../assets/buildings/Desert/Atomic/IndustrialFarms.png";
import Hol_IndustrialFarms from "../assets/buildings/Hollow/Atomic/IndustrialFarms.png";
/*WATER*/
import Ter_UrbanWaterNetworks from "../assets/buildings/Terran/Atomic/UrbanWaterNetworks.png";
import Gas_UrbanWaterNetworks from "../assets/buildings/GasGiant/Atomic/UrbanWaterNetworks.png";
import Fro_UrbanWaterNetworks from "../assets/buildings/Frozen/Atomic/UrbanWaterNetworks.png";
import Lav_UrbanWaterNetworks from "../assets/buildings/Lava/Atomic/UrbanWaterNetworks.png";
import Oce_UrbanWaterNetworks from "../assets/buildings/Oceanic/Atomic/UrbanWaterNetworks.png";
import Des_UrbanWaterNetworks from "../assets/buildings/Desert/Atomic/UrbanWaterNetworks.png";
import Hol_UrbanWaterNetworks from "../assets/buildings/Hollow/Atomic/UrbanWaterNetworks.png";
/*POPULATION*/
import Ter_Metropolises from "../assets/buildings/Terran/Atomic/Metropolises.png";
import Gas_Metropolises from "../assets/buildings/GasGiant/Atomic/Metropolises.png";
import Fro_Metropolises from "../assets/buildings/Frozen/Atomic/Metropolises.png";
import Lav_Metropolises from "../assets/buildings/Lava/Atomic/Metropolises.png";
import Oce_Metropolises from "../assets/buildings/Oceanic/Atomic/Metropolises.png";
import Des_Metropolises from "../assets/buildings/Desert/Atomic/Metropolises.png";
import Hol_Metropolises from "../assets/buildings/Hollow/Atomic/Metropolises.png";
/*ENERGY*/
import Ter_NuclearPower from "../assets/buildings/Terran/Atomic/NuclearPower.png";
import Gas_NuclearPower from "../assets/buildings/GasGiant/Atomic/NuclearPower.png";
import Fro_NuclearPower from "../assets/buildings/Frozen/Atomic/NuclearPower.png";
import Lav_NuclearPower from "../assets/buildings/Lava/Atomic/NuclearPower.png";
import Oce_NuclearPower from "../assets/buildings/Oceanic/Atomic/NuclearPower.png";
import Des_NuclearPower from "../assets/buildings/Desert/Atomic/NuclearPower.png";
import Hol_NuclearPower from "../assets/buildings/Hollow/Atomic/NuclearPower.png";
/*ORE*/
/*SCIENCE*/
/*****************/
/*NEXUS*/
/*****************/
/*FOOD*/

/*WATER*/

/*POPULATION*/
/*ENERGY*/

/*ORE*/

/*SCIENCE*/

/*****************/
/*GALAXTIC*/
/*****************/
/*FOOD*/

/*WATER*/

/*POPULATION*/

/*ENERGY*/

/*ORE*/

/*SCIENCE*/

/*
import mineMetal from "../assets/buildings/excavation/mine-metal.jpg";
import dam from "../assets/buildings/nourishment/dam.jpg";
import farm from "../assets/buildings/nourishment/farm.jpg";
*/

import { BG_Planet, DiurnalCycle, GlobalIcon, MenuButtons, Morale, Planet, R_BuildingType, Race, Resource, Splash, WeatherEvent } from "../structs/Enums";

const mapSplash = {
  [Splash.Landing]: landing,
  [Splash.Creating]: creating,
};
const mapGlobalIcon = {
  [GlobalIcon.App]: app,
};

const menuButtons = {
  [MenuButtons.Home]: Home,
  [MenuButtons.Civilization]: Civilization,
  [MenuButtons.Citizens]: Citizens,
  [MenuButtons.Nourishment]: Nourishment,
  [MenuButtons.Residence]: Residence,
  [MenuButtons.PowerSrc]: PowerSource,
  [MenuButtons.Excavation]: Excavation,
  [MenuButtons.Education]: Education,
  [MenuButtons.Technology]: Technology,
  [MenuButtons.Research]: Research,
  [MenuButtons.Manufacturing]: Manufacturing,
  [MenuButtons.Hangar]: Hangar,
  [MenuButtons.OSOS]: OSOS,
  [MenuButtons.Expeditions]: Expedition,
  [MenuButtons.Events]: Events,
};

const mapBGPlanet = {
  [BG_Planet.Terran]: BG_Terran,
  [BG_Planet.GasGiant]: BG_GasGiant,
  [BG_Planet.Frozen]: BG_Frozen,
  [BG_Planet.Lava]: BG_Lava,
  [BG_Planet.Oceanic]: BG_Oceanic,
  [BG_Planet.Desert]: BG_Desert,
  [BG_Planet.Hollow]: BG_Hollow,
};
const mapPlanet = {
  [Planet.Terran]: Terran,
  [Planet.GasGiant]: GasGiant,
  [Planet.Frozen]: Frozen,
  [Planet.Lava]: Lava,
  [Planet.Oceanic]: Oceanic,
  [Planet.Desert]: Desert,
  [Planet.Hollow]: Hollow,
};

const diurnalCycle = {
  [DiurnalCycle.Day]: Day,
  [DiurnalCycle.Night]: Night,
};

const mapWeather = {
  [WeatherEvent.ClearSkies]: ClearSkies,
  [WeatherEvent.Humid]: Humid,
  [WeatherEvent.Heatwave]: Heatwave,
  [WeatherEvent.Cloudy]: Cloudy,
  [WeatherEvent.Fog]: Fog,
  [WeatherEvent.Overcast]: Overcast,
  [WeatherEvent.Windy]: Windy,
  [WeatherEvent.IsolatedShowers]: IsolatedShowers,
  [WeatherEvent.Rain]: Rain,
  [WeatherEvent.HeavyShowers]: HeavyShowers,
  [WeatherEvent.Thunderstorms]: Thunderstorms,
  [WeatherEvent.Snow]: Snow,
  [WeatherEvent.Hail]: Hail,
  [WeatherEvent.Freezing]: Freezing,
};

const races = {
  [Race.Verdani]: Verdani,
  [Race.Terrarchs]: Terrarchs,
  [Race.Nebulons]: Nebulons,
  [Race.Stratosians]: Stratosians,
  [Race.Cryonics]: Cryonics,
  [Race.Frostborne]: Frostborne,
  [Race.Magmarians]: Magmarians,
  [Race.ForgeLords]: ForgeLords,
  [Race.Aquarians]: Aquarians,
  [Race.Mareans]: Mareans,
  [Race.Solarians]: Solarians,
  [Race.DuneWanderers]: DuneWanderers,
  [Race.CavernKeepers]: CavernKeepers,
  [Race.Echoes]: Echoes,
};

const morale = {
  [Morale.High]: High,
  [Morale.Neutral]: Neutral,
  [Morale.Low]: Low,
};

const mapResource = {
  [Resource.Food]: Food,
  [Resource.Water]: Water,
  [Resource.Population]: Population,
  [Resource.Energy]: Energy,
  [Resource.Ore]: Ore,
  [Resource.Science]: Science,
  [Resource.ArcaneRelic]: ArcaneRelic,
  [Resource.AquaGem]: AquaGem,
  [Resource.Biomass]: Biomass,
  [Resource.CryoCrystal]: CryoCrystal,
  [Resource.Helium3]: Helium3,
  [Resource.Magmacite]: Magmacite,
  [Resource.Solarite]: Solarite,
};

const buildings = {
  [Planet.Terran]: {
    /*ANCIENT*/
    /*****************/
    /*FOOD*/
    [R_BuildingType.HuntingAndGathering]: Ter_HuntingAndGathering,
    [R_BuildingType.SubsistenceFarms]: Ter_SubsistenceFarms,
    [R_BuildingType.PastoralFarms]: Ter_PastoralFarms,
    /*WATER*/
    [R_BuildingType.NaturalWaterways]: Ter_NaturalWaterways,
    [R_BuildingType.RainwaterHarvesting]: Ter_RainwaterHarvesting,
    /*POPULATION*/
    [R_BuildingType.NomadicTribes]: Ter_NomadicTribes,
    [R_BuildingType.Hamlets]: Ter_Hamlets,
    /*ENERGY*/
    [R_BuildingType.BiofuelBurning]: Ter_BiofuelBurning,
    /*ORE*/
    [R_BuildingType.SurfaceMining]: Ter_SurfaceMining,
    /*SCIENCE*/
    /*****************/
    /*IMPERIAL*/
    /*****************/
    /*FOOD*/
    [R_BuildingType.MixedFarms]: Ter_MixedFarms,
    /*WATER*/
    [R_BuildingType.Wells]: Ter_Wells,
    [R_BuildingType.Cisterns]: Ter_Cisterns,
    [R_BuildingType.Aqueducts]: Ter_Aqueducts,
    /*POPULATION*/
    [R_BuildingType.Villages]: Ter_Villages,
    [R_BuildingType.ProtoCities]: Ter_ProtoCities,
    /*ENERGY*/
    [R_BuildingType.Windmills]: Ter_Windmills,
    [R_BuildingType.Watermills]: Ter_Watermills,
    /*ORE*/
    [R_BuildingType.AquaGem]: placeholder,
    [R_BuildingType.ArcaneRelic]: placeholder,
    [R_BuildingType.Biomass]: Ter_Biomass,
    [R_BuildingType.CryoCrystal]: placeholder,
    [R_BuildingType.Helium3]: placeholder,
    [R_BuildingType.Magmacite]: placeholder,
    [R_BuildingType.Solarite]: placeholder,
    /*SCIENCE*/
    [R_BuildingType.Schools]: Ter_Schools,
    [R_BuildingType.Universities]: Ter_Universities,
    /*****************/
    /*INDUSTIAL*/
    /*****************/
    /*FOOD*/
    [R_BuildingType.CommercialFarms]: Ter_CommercialFarms,
    /*WATER*/
    [R_BuildingType.Reservoirs]: Ter_Reservoirs,
    [R_BuildingType.AquiferAccessPoints]: Ter_AquiferAccessPoints,
    /*POPULATION*/
    [R_BuildingType.Cities]: Ter_Cities,
    /*ENERGY*/
    [R_BuildingType.CoalAndSteamPower]: placeholder,
    [R_BuildingType.CrudeOilAndGasEnergy]: Ter_CrudeOilAndGasEnergy,
    [R_BuildingType.HydroelectricPower]: placeholder,
    /*ORE*/
    [R_BuildingType.SubterraneanMining]: placeholder,
    [R_BuildingType.Quarrying]: Ter_Quarrying,
    /*SCIENCE*/
    [R_BuildingType.GraduateSchools]: Ter_GraduateSchools,
    [R_BuildingType.Factories]: Ter_Factories,
    /*****************/
    /*ATOMIC*/
    /*****************/
    /*FOOD*/
    [R_BuildingType.IndustrialFarms]: Ter_IndustrialFarms,
    /*WATER*/
    [R_BuildingType.UrbanWaterNetworks]: Ter_UrbanWaterNetworks,
    /*POPULATION*/
    [R_BuildingType.Metropolises]: Ter_Metropolises,
    /*ENERGY*/
    [R_BuildingType.NuclearPower]: Ter_NuclearPower,
    /*ORE*/
    /*SCIENCE*/
    /*****************/
    /*NEXUS*/
    /*****************/
    /*FOOD*/
    [R_BuildingType.GMOFarming]: placeholder,
    [R_BuildingType.AquaticFarming]: placeholder,
    /*WATER*/
    [R_BuildingType.DesalinationPlants]: placeholder,
    /*POPULATION*/
    /*ENERGY*/
    [R_BuildingType.SolarPanels]: placeholder,
    [R_BuildingType.WindTurbines]: placeholder,
    [R_BuildingType.GeothermalEnergy]: placeholder,
    /*ORE*/
    [R_BuildingType.TrenchMining]: placeholder,
    [R_BuildingType.ChemicalMining]: placeholder,
    /*SCIENCE*/
    [R_BuildingType.Electronics]: placeholder,
    [R_BuildingType.Computers]: placeholder,
    [R_BuildingType.Cyberspace]: placeholder,
    /*****************/
    /*GALAXTIC*/
    /*****************/
    /*FOOD*/
    [R_BuildingType.VerticalFarming]: placeholder,
    [R_BuildingType.BioEngineeredMeat]: placeholder,
    [R_BuildingType.SyntheticFoodCreation]: placeholder,
    /*WATER*/
    [R_BuildingType.AutomatedWaterNetworks]: placeholder,
    [R_BuildingType.AtmosphericWaterExtractors]: placeholder,
    [R_BuildingType.OrbitalWaterHarvesting]: placeholder,
    /*POPULATION*/
    [R_BuildingType.CyberCities]: placeholder,
    [R_BuildingType.VerticalMetropolises]: placeholder,
    [R_BuildingType.Aquapolis]: placeholder,
    /*ENERGY*/
    [R_BuildingType.FusionPower]: placeholder,
    [R_BuildingType.OrbitalSolarEnergy]: placeholder,
    [R_BuildingType.MarineHydrokineticPower]: placeholder,
    /*ORE*/
    [R_BuildingType.AutonomousMining]: placeholder,
    [R_BuildingType.AsteroidMining]: placeholder,
    /*SCIENCE*/
    [R_BuildingType.ArtificialIntelligence]: placeholder,
  },
  [Planet.GasGiant]: {
    /*ANCIENT*/
    /*****************/
    /*FOOD*/
    [R_BuildingType.HuntingAndGathering]: Gas_HuntingAndGathering,
    [R_BuildingType.SubsistenceFarms]: Gas_SubsistenceFarms,
    [R_BuildingType.PastoralFarms]: Gas_PastoralFarms,
    /*WATER*/
    [R_BuildingType.NaturalWaterways]: Gas_NaturalWaterways,
    [R_BuildingType.RainwaterHarvesting]: Gas_RainwaterHarvesting,
    /*POPULATION*/
    [R_BuildingType.NomadicTribes]: Gas_NomadicTribes,
    [R_BuildingType.Hamlets]: Gas_Hamlets,
    /*ENERGY*/
    [R_BuildingType.BiofuelBurning]: Gas_BiofuelBurning,
    /*ORE*/
    [R_BuildingType.SurfaceMining]: Gas_SurfaceMining,
    /*SCIENCE*/
    /*****************/
    /*IMPERIAL*/
    /*****************/
    /*FOOD*/
    [R_BuildingType.MixedFarms]: Gas_MixedFarms,
    /*WATER*/
    [R_BuildingType.Wells]: Gas_Wells,
    [R_BuildingType.Cisterns]: Gas_Cisterns,
    [R_BuildingType.Aqueducts]: Gas_Aqueducts,
    /*POPULATION*/
    [R_BuildingType.Villages]: Gas_Villages,
    [R_BuildingType.ProtoCities]: Gas_ProtoCities,
    /*ENERGY*/
    [R_BuildingType.Windmills]: Gas_Windmills,
    [R_BuildingType.Watermills]: Gas_Watermills,
    /*ORE*/
    [R_BuildingType.AquaGem]: placeholder,
    [R_BuildingType.ArcaneRelic]: placeholder,
    [R_BuildingType.Biomass]: placeholder,
    [R_BuildingType.CryoCrystal]: placeholder,
    [R_BuildingType.Helium3]: Gas_Helium3,
    [R_BuildingType.Magmacite]: placeholder,
    [R_BuildingType.Solarite]: placeholder,
    /*SCIENCE*/
    [R_BuildingType.Schools]: Gas_Schools,
    [R_BuildingType.Universities]: Gas_Universities,
    /*****************/
    /*INDUSTIAL*/
    /*****************/
    /*FOOD*/
    [R_BuildingType.CommercialFarms]: Gas_CommercialFarms,
    /*WATER*/
    [R_BuildingType.Reservoirs]: Gas_Reservoirs,
    [R_BuildingType.AquiferAccessPoints]: Gas_AquiferAccessPoints,
    /*POPULATION*/
    [R_BuildingType.Cities]: Gas_Cities,
    /*ENERGY*/
    [R_BuildingType.CoalAndSteamPower]: placeholder,
    [R_BuildingType.CrudeOilAndGasEnergy]: Gas_CrudeOilAndGasEnergy,
    [R_BuildingType.HydroelectricPower]: placeholder,
    /*ORE*/
    [R_BuildingType.SubterraneanMining]: placeholder,
    [R_BuildingType.Quarrying]: Gas_Quarrying,
    /*SCIENCE*/
    [R_BuildingType.GraduateSchools]: Gas_GraduateSchools,
    [R_BuildingType.Factories]: Gas_Factories,
    /*****************/
    /*ATOMIC*/
    /*****************/
    /*FOOD*/
    [R_BuildingType.IndustrialFarms]: Gas_IndustrialFarms,
    /*WATER*/
    [R_BuildingType.UrbanWaterNetworks]: Gas_UrbanWaterNetworks,
    /*POPULATION*/
    [R_BuildingType.Metropolises]: Gas_Metropolises,
    /*ENERGY*/
    [R_BuildingType.NuclearPower]: Gas_NuclearPower,
    /*ORE*/
    /*SCIENCE*/
    /*****************/
    /*NEXUS*/
    /*****************/
    /*FOOD*/
    [R_BuildingType.GMOFarming]: placeholder,
    [R_BuildingType.AquaticFarming]: placeholder,
    /*WATER*/
    [R_BuildingType.DesalinationPlants]: placeholder,
    /*POPULATION*/
    /*ENERGY*/
    [R_BuildingType.SolarPanels]: placeholder,
    [R_BuildingType.WindTurbines]: placeholder,
    [R_BuildingType.GeothermalEnergy]: placeholder,
    /*ORE*/
    [R_BuildingType.TrenchMining]: placeholder,
    [R_BuildingType.ChemicalMining]: placeholder,
    /*SCIENCE*/
    [R_BuildingType.Electronics]: placeholder,
    [R_BuildingType.Computers]: placeholder,
    [R_BuildingType.Cyberspace]: placeholder,
    /*****************/
    /*GALAXTIC*/
    /*****************/
    /*FOOD*/
    [R_BuildingType.VerticalFarming]: placeholder,
    [R_BuildingType.BioEngineeredMeat]: placeholder,
    [R_BuildingType.SyntheticFoodCreation]: placeholder,
    /*WATER*/
    [R_BuildingType.AutomatedWaterNetworks]: placeholder,
    [R_BuildingType.AtmosphericWaterExtractors]: placeholder,
    [R_BuildingType.OrbitalWaterHarvesting]: placeholder,
    /*POPULATION*/
    [R_BuildingType.CyberCities]: placeholder,
    [R_BuildingType.VerticalMetropolises]: placeholder,
    [R_BuildingType.Aquapolis]: placeholder,
    /*ENERGY*/
    [R_BuildingType.FusionPower]: placeholder,
    [R_BuildingType.OrbitalSolarEnergy]: placeholder,
    [R_BuildingType.MarineHydrokineticPower]: placeholder,
    /*ORE*/
    [R_BuildingType.AutonomousMining]: placeholder,
    [R_BuildingType.AsteroidMining]: placeholder,
    /*SCIENCE*/
    [R_BuildingType.ArtificialIntelligence]: placeholder,
  },
  [Planet.Frozen]: {
    /*ANCIENT*/
    /*****************/
    /*FOOD*/
    [R_BuildingType.HuntingAndGathering]: Fro_HuntingAndGathering,
    [R_BuildingType.SubsistenceFarms]: Fro_SubsistenceFarms,
    [R_BuildingType.PastoralFarms]: Fro_PastoralFarms,
    /*WATER*/
    [R_BuildingType.NaturalWaterways]: Fro_NaturalWaterways,
    [R_BuildingType.RainwaterHarvesting]: Fro_RainwaterHarvesting,
    /*POPULATION*/
    [R_BuildingType.NomadicTribes]: Fro_NomadicTribes,
    [R_BuildingType.Hamlets]: Fro_Hamlets,
    /*ENERGY*/
    [R_BuildingType.BiofuelBurning]: Fro_BiofuelBurning,
    /*ORE*/
    [R_BuildingType.SurfaceMining]: Fro_SurfaceMining,
    /*SCIENCE*/
    /*****************/
    /*IMPERIAL*/
    /*****************/
    /*FOOD*/
    [R_BuildingType.MixedFarms]: Fro_MixedFarms,
    /*WATER*/
    [R_BuildingType.Wells]: Fro_Wells,
    [R_BuildingType.Cisterns]: Fro_Cisterns,
    [R_BuildingType.Aqueducts]: Fro_Aqueducts,
    /*POPULATION*/
    [R_BuildingType.Villages]: Fro_Villages,
    [R_BuildingType.ProtoCities]: Fro_ProtoCities,
    /*ENERGY*/
    [R_BuildingType.Windmills]: Fro_Windmills,
    [R_BuildingType.Watermills]: Fro_Watermills,
    /*ORE*/
    [R_BuildingType.AquaGem]: placeholder,
    [R_BuildingType.ArcaneRelic]: placeholder,
    [R_BuildingType.Biomass]: placeholder,
    [R_BuildingType.CryoCrystal]: Fro_CryoCrystal,
    [R_BuildingType.Helium3]: placeholder,
    [R_BuildingType.Magmacite]: placeholder,
    [R_BuildingType.Solarite]: placeholder,
    /*SCIENCE*/
    [R_BuildingType.Schools]: Fro_Schools,
    [R_BuildingType.Universities]: Fro_Universities,
    /*****************/
    /*INDUSTIAL*/
    /*****************/
    /*FOOD*/
    [R_BuildingType.CommercialFarms]: Fro_CommercialFarms,
    /*WATER*/
    [R_BuildingType.Reservoirs]: Fro_Reservoirs,
    [R_BuildingType.AquiferAccessPoints]: Fro_AquiferAccessPoints,
    /*POPULATION*/
    [R_BuildingType.Cities]: Fro_Cities,
    /*ENERGY*/
    [R_BuildingType.CoalAndSteamPower]: placeholder,
    [R_BuildingType.CrudeOilAndGasEnergy]: Fro_CrudeOilAndGasEnergy,
    [R_BuildingType.HydroelectricPower]: placeholder,
    /*ORE*/
    [R_BuildingType.SubterraneanMining]: placeholder,
    [R_BuildingType.Quarrying]: Fro_Quarrying,
    /*SCIENCE*/
    [R_BuildingType.GraduateSchools]: Fro_GraduateSchools,
    [R_BuildingType.Factories]: Fro_Factories,
    /*****************/
    /*ATOMIC*/
    /*****************/
    /*FOOD*/
    [R_BuildingType.IndustrialFarms]: Fro_IndustrialFarms,
    /*WATER*/
    [R_BuildingType.UrbanWaterNetworks]: Fro_UrbanWaterNetworks,
    /*POPULATION*/
    [R_BuildingType.Metropolises]: Fro_Metropolises,
    /*ENERGY*/
    [R_BuildingType.NuclearPower]: Fro_NuclearPower,
    /*ORE*/
    /*SCIENCE*/
    /*****************/
    /*NEXUS*/
    /*****************/
    /*FOOD*/
    [R_BuildingType.GMOFarming]: placeholder,
    [R_BuildingType.AquaticFarming]: placeholder,
    /*WATER*/
    [R_BuildingType.DesalinationPlants]: placeholder,
    /*POPULATION*/
    /*ENERGY*/
    [R_BuildingType.SolarPanels]: placeholder,
    [R_BuildingType.WindTurbines]: placeholder,
    [R_BuildingType.GeothermalEnergy]: placeholder,
    /*ORE*/
    [R_BuildingType.TrenchMining]: placeholder,
    [R_BuildingType.ChemicalMining]: placeholder,
    /*SCIENCE*/
    [R_BuildingType.Electronics]: placeholder,
    [R_BuildingType.Computers]: placeholder,
    [R_BuildingType.Cyberspace]: placeholder,
    /*****************/
    /*GALAXTIC*/
    /*****************/
    /*FOOD*/
    [R_BuildingType.VerticalFarming]: placeholder,
    [R_BuildingType.BioEngineeredMeat]: placeholder,
    [R_BuildingType.SyntheticFoodCreation]: placeholder,
    /*WATER*/
    [R_BuildingType.AutomatedWaterNetworks]: placeholder,
    [R_BuildingType.AtmosphericWaterExtractors]: placeholder,
    [R_BuildingType.OrbitalWaterHarvesting]: placeholder,
    /*POPULATION*/
    [R_BuildingType.CyberCities]: placeholder,
    [R_BuildingType.VerticalMetropolises]: placeholder,
    [R_BuildingType.Aquapolis]: placeholder,
    /*ENERGY*/
    [R_BuildingType.FusionPower]: placeholder,
    [R_BuildingType.OrbitalSolarEnergy]: placeholder,
    [R_BuildingType.MarineHydrokineticPower]: placeholder,
    /*ORE*/
    [R_BuildingType.AutonomousMining]: placeholder,
    [R_BuildingType.AsteroidMining]: placeholder,
    /*SCIENCE*/
    [R_BuildingType.ArtificialIntelligence]: placeholder,
  },
  [Planet.Lava]: {
    /*ANCIENT*/
    /*****************/
    /*FOOD*/
    [R_BuildingType.HuntingAndGathering]: Lav_HuntingAndGathering,
    [R_BuildingType.SubsistenceFarms]: Lav_SubsistenceFarms,
    [R_BuildingType.PastoralFarms]: Lav_PastoralFarms,
    /*WATER*/
    [R_BuildingType.NaturalWaterways]: Lav_NaturalWaterways,
    [R_BuildingType.RainwaterHarvesting]: Lav_RainwaterHarvesting,
    /*POPULATION*/
    [R_BuildingType.NomadicTribes]: Lav_NomadicTribes,
    [R_BuildingType.Hamlets]: Lav_Hamlets,
    /*ENERGY*/
    [R_BuildingType.BiofuelBurning]: Lav_BiofuelBurning,
    /*ORE*/
    [R_BuildingType.SurfaceMining]: Lav_SurfaceMining,
    /*SCIENCE*/
    /*****************/
    /*IMPERIAL*/
    /*****************/
    /*FOOD*/
    [R_BuildingType.MixedFarms]: Lav_MixedFarms,
    /*WATER*/
    [R_BuildingType.Wells]: Lav_Wells,
    [R_BuildingType.Cisterns]: Lav_Cisterns,
    [R_BuildingType.Aqueducts]: Lav_Aqueducts,
    /*POPULATION*/
    [R_BuildingType.Villages]: Lav_Villages,
    [R_BuildingType.ProtoCities]: Lav_ProtoCities,
    /*ENERGY*/
    [R_BuildingType.Windmills]: Lav_Windmills,
    [R_BuildingType.Watermills]: Lav_Watermills,
    /*ORE*/
    [R_BuildingType.AquaGem]: placeholder,
    [R_BuildingType.ArcaneRelic]: placeholder,
    [R_BuildingType.Biomass]: placeholder,
    [R_BuildingType.CryoCrystal]: placeholder,
    [R_BuildingType.Helium3]: placeholder,
    [R_BuildingType.Magmacite]: Lav_Magmacite,
    [R_BuildingType.Solarite]: placeholder,
    /*SCIENCE*/
    [R_BuildingType.Schools]: Lav_Schools,
    [R_BuildingType.Universities]: Lav_Universities,
    /*****************/
    /*INDUSTIAL*/
    /*****************/
    /*FOOD*/
    [R_BuildingType.CommercialFarms]: Lav_CommercialFarms,
    /*WATER*/
    [R_BuildingType.Reservoirs]: Lav_Reservoirs,
    [R_BuildingType.AquiferAccessPoints]: Lav_AquiferAccessPoints,
    /*POPULATION*/
    [R_BuildingType.Cities]: Lav_Cities,
    /*ENERGY*/
    [R_BuildingType.CoalAndSteamPower]: placeholder,
    [R_BuildingType.CrudeOilAndGasEnergy]: Lav_CrudeOilAndGasEnergy,
    [R_BuildingType.HydroelectricPower]: placeholder,
    /*ORE*/
    [R_BuildingType.SubterraneanMining]: placeholder,
    [R_BuildingType.Quarrying]: Lav_Quarrying,
    /*SCIENCE*/
    [R_BuildingType.GraduateSchools]: Lav_GraduateSchools,
    [R_BuildingType.Factories]: Lav_Factories,
    /*****************/
    /*ATOMIC*/
    /*****************/
    /*FOOD*/
    [R_BuildingType.IndustrialFarms]: Lav_IndustrialFarms,
    /*WATER*/
    [R_BuildingType.UrbanWaterNetworks]: Lav_UrbanWaterNetworks,
    /*POPULATION*/
    [R_BuildingType.Metropolises]: Lav_Metropolises,
    /*ENERGY*/
    [R_BuildingType.NuclearPower]: Lav_NuclearPower,
    /*ORE*/
    /*SCIENCE*/
    /*****************/
    /*NEXUS*/
    /*****************/
    /*FOOD*/
    [R_BuildingType.GMOFarming]: placeholder,
    [R_BuildingType.AquaticFarming]: placeholder,
    /*WATER*/
    [R_BuildingType.DesalinationPlants]: placeholder,
    /*POPULATION*/
    /*ENERGY*/
    [R_BuildingType.SolarPanels]: placeholder,
    [R_BuildingType.WindTurbines]: placeholder,
    [R_BuildingType.GeothermalEnergy]: placeholder,
    /*ORE*/
    [R_BuildingType.TrenchMining]: placeholder,
    [R_BuildingType.ChemicalMining]: placeholder,
    /*SCIENCE*/
    [R_BuildingType.Electronics]: placeholder,
    [R_BuildingType.Computers]: placeholder,
    [R_BuildingType.Cyberspace]: placeholder,
    /*****************/
    /*GALAXTIC*/
    /*****************/
    /*FOOD*/
    [R_BuildingType.VerticalFarming]: placeholder,
    [R_BuildingType.BioEngineeredMeat]: placeholder,
    [R_BuildingType.SyntheticFoodCreation]: placeholder,
    /*WATER*/
    [R_BuildingType.AutomatedWaterNetworks]: placeholder,
    [R_BuildingType.AtmosphericWaterExtractors]: placeholder,
    [R_BuildingType.OrbitalWaterHarvesting]: placeholder,
    /*POPULATION*/
    [R_BuildingType.CyberCities]: placeholder,
    [R_BuildingType.VerticalMetropolises]: placeholder,
    [R_BuildingType.Aquapolis]: placeholder,
    /*ENERGY*/
    [R_BuildingType.FusionPower]: placeholder,
    [R_BuildingType.OrbitalSolarEnergy]: placeholder,
    [R_BuildingType.MarineHydrokineticPower]: placeholder,
    /*ORE*/
    [R_BuildingType.AutonomousMining]: placeholder,
    [R_BuildingType.AsteroidMining]: placeholder,
    /*SCIENCE*/
    [R_BuildingType.ArtificialIntelligence]: placeholder,
  },
  [Planet.Oceanic]: {
    /*ANCIENT*/
    /*****************/
    /*FOOD*/
    [R_BuildingType.HuntingAndGathering]: Oce_HuntingAndGathering,
    [R_BuildingType.SubsistenceFarms]: Oce_SubsistenceFarms,
    [R_BuildingType.PastoralFarms]: Oce_PastoralFarms,
    /*WATER*/
    [R_BuildingType.NaturalWaterways]: Oce_NaturalWaterways,
    [R_BuildingType.RainwaterHarvesting]: Oce_RainwaterHarvesting,
    /*POPULATION*/
    [R_BuildingType.NomadicTribes]: Oce_NomadicTribes,
    [R_BuildingType.Hamlets]: Oce_Hamlets,
    /*ENERGY*/
    [R_BuildingType.BiofuelBurning]: Oce_BiofuelBurning,
    /*ORE*/
    [R_BuildingType.SurfaceMining]: Oce_SurfaceMining,
    /*SCIENCE*/
    /*****************/
    /*IMPERIAL*/
    /*****************/
    /*FOOD*/
    [R_BuildingType.MixedFarms]: Oce_MixedFarms,
    /*WATER*/
    [R_BuildingType.Wells]: Oce_Wells,
    [R_BuildingType.Cisterns]: Oce_Cisterns,
    [R_BuildingType.Aqueducts]: Oce_Aqueducts,
    /*POPULATION*/
    [R_BuildingType.Villages]: Oce_Villages,
    [R_BuildingType.ProtoCities]: Oce_ProtoCities,
    /*ENERGY*/
    [R_BuildingType.Windmills]: Oce_Windmills,
    [R_BuildingType.Watermills]: Oce_Watermills,
    /*ORE*/
    [R_BuildingType.AquaGem]: Oce_AquaGem,
    [R_BuildingType.ArcaneRelic]: placeholder,
    [R_BuildingType.Biomass]: placeholder,
    [R_BuildingType.CryoCrystal]: placeholder,
    [R_BuildingType.Helium3]: placeholder,
    [R_BuildingType.Magmacite]: placeholder,
    [R_BuildingType.Solarite]: placeholder,
    /*SCIENCE*/
    [R_BuildingType.Schools]: Oce_Schools,
    [R_BuildingType.Universities]: Oce_Universities,
    /*****************/
    /*INDUSTIAL*/
    /*****************/
    /*FOOD*/
    [R_BuildingType.CommercialFarms]: Oce_CommercialFarms,
    /*WATER*/
    [R_BuildingType.Reservoirs]: Oce_Reservoirs,
    [R_BuildingType.AquiferAccessPoints]: Oce_AquiferAccessPoints,
    /*POPULATION*/
    [R_BuildingType.Cities]: Oce_Cities,
    /*ENERGY*/
    [R_BuildingType.CoalAndSteamPower]: placeholder,
    [R_BuildingType.CrudeOilAndGasEnergy]: Oce_CrudeOilAndGasEnergy,
    [R_BuildingType.HydroelectricPower]: placeholder,
    /*ORE*/
    [R_BuildingType.SubterraneanMining]: placeholder,
    [R_BuildingType.Quarrying]: Oce_Quarrying,
    /*SCIENCE*/
    [R_BuildingType.GraduateSchools]: Oce_GraduateSchools,
    [R_BuildingType.Factories]: Oce_Factories,
    /*****************/
    /*ATOMIC*/
    /*****************/
    /*FOOD*/
    [R_BuildingType.IndustrialFarms]: Oce_IndustrialFarms,
    /*WATER*/
    [R_BuildingType.UrbanWaterNetworks]: Oce_UrbanWaterNetworks,
    /*POPULATION*/
    [R_BuildingType.Metropolises]: Oce_Metropolises,
    /*ENERGY*/
    [R_BuildingType.NuclearPower]: Oce_NuclearPower,
    /*ORE*/
    /*SCIENCE*/
    /*****************/
    /*NEXUS*/
    /*****************/
    /*FOOD*/
    [R_BuildingType.GMOFarming]: placeholder,
    [R_BuildingType.AquaticFarming]: placeholder,
    /*WATER*/
    [R_BuildingType.DesalinationPlants]: placeholder,
    /*POPULATION*/
    /*ENERGY*/
    [R_BuildingType.SolarPanels]: placeholder,
    [R_BuildingType.WindTurbines]: placeholder,
    [R_BuildingType.GeothermalEnergy]: placeholder,
    /*ORE*/
    [R_BuildingType.TrenchMining]: placeholder,
    [R_BuildingType.ChemicalMining]: placeholder,
    /*SCIENCE*/
    [R_BuildingType.Electronics]: placeholder,
    [R_BuildingType.Computers]: placeholder,
    [R_BuildingType.Cyberspace]: placeholder,
    /*****************/
    /*GALAXTIC*/
    /*****************/
    /*FOOD*/
    [R_BuildingType.VerticalFarming]: placeholder,
    [R_BuildingType.BioEngineeredMeat]: placeholder,
    [R_BuildingType.SyntheticFoodCreation]: placeholder,
    /*WATER*/
    [R_BuildingType.AutomatedWaterNetworks]: placeholder,
    [R_BuildingType.AtmosphericWaterExtractors]: placeholder,
    [R_BuildingType.OrbitalWaterHarvesting]: placeholder,
    /*POPULATION*/
    [R_BuildingType.CyberCities]: placeholder,
    [R_BuildingType.VerticalMetropolises]: placeholder,
    [R_BuildingType.Aquapolis]: placeholder,
    /*ENERGY*/
    [R_BuildingType.FusionPower]: placeholder,
    [R_BuildingType.OrbitalSolarEnergy]: placeholder,
    [R_BuildingType.MarineHydrokineticPower]: placeholder,
    /*ORE*/
    [R_BuildingType.AutonomousMining]: placeholder,
    [R_BuildingType.AsteroidMining]: placeholder,
    /*SCIENCE*/
    [R_BuildingType.ArtificialIntelligence]: placeholder,
  },
  [Planet.Desert]: {
    /*ANCIENT*/
    /*****************/
    /*FOOD*/
    [R_BuildingType.HuntingAndGathering]: Des_HuntingAndGathering,
    [R_BuildingType.SubsistenceFarms]: Des_SubsistenceFarms,
    [R_BuildingType.PastoralFarms]: Des_PastoralFarms,
    /*WATER*/
    [R_BuildingType.NaturalWaterways]: Des_NaturalWaterways,
    [R_BuildingType.RainwaterHarvesting]: Des_RainwaterHarvesting,
    /*POPULATION*/
    [R_BuildingType.NomadicTribes]: Des_NomadicTribes,
    [R_BuildingType.Hamlets]: Des_Hamlets,
    /*ENERGY*/
    [R_BuildingType.BiofuelBurning]: Des_BiofuelBurning,
    /*ORE*/
    [R_BuildingType.SurfaceMining]: Des_SurfaceMining,
    /*SCIENCE*/
    /*****************/
    /*IMPERIAL*/
    /*****************/
    /*FOOD*/
    [R_BuildingType.MixedFarms]: Des_MixedFarms,
    /*WATER*/
    [R_BuildingType.Wells]: Des_Wells,
    [R_BuildingType.Cisterns]: Des_Cisterns,
    [R_BuildingType.Aqueducts]: Des_Aqueducts,
    /*POPULATION*/
    [R_BuildingType.Villages]: Des_Villages,
    [R_BuildingType.ProtoCities]: Des_ProtoCities,
    /*ENERGY*/
    [R_BuildingType.Windmills]: Des_Windmills,
    [R_BuildingType.Watermills]: Des_Watermills,
    /*ORE*/
    [R_BuildingType.AquaGem]: placeholder,
    [R_BuildingType.ArcaneRelic]: placeholder,
    [R_BuildingType.Biomass]: placeholder,
    [R_BuildingType.CryoCrystal]: placeholder,
    [R_BuildingType.Helium3]: placeholder,
    [R_BuildingType.Magmacite]: placeholder,
    [R_BuildingType.Solarite]: Des_Solarite,
    /*SCIENCE*/
    [R_BuildingType.Schools]: Des_Schools,
    [R_BuildingType.Universities]: Des_Universities,
    /*****************/
    /*INDUSTIAL*/
    /*****************/
    /*FOOD*/
    [R_BuildingType.CommercialFarms]: Des_CommercialFarms,
    /*WATER*/
    [R_BuildingType.Reservoirs]: Des_Reservoirs,
    [R_BuildingType.AquiferAccessPoints]: Des_AquiferAccessPoints,
    /*POPULATION*/
    [R_BuildingType.Cities]: Des_Cities,
    /*ENERGY*/
    [R_BuildingType.CoalAndSteamPower]: placeholder,
    [R_BuildingType.CrudeOilAndGasEnergy]: Des_CrudeOilAndGasEnergy,
    [R_BuildingType.HydroelectricPower]: placeholder,
    /*ORE*/
    [R_BuildingType.SubterraneanMining]: placeholder,
    [R_BuildingType.Quarrying]: Des_Quarrying,
    /*SCIENCE*/
    [R_BuildingType.GraduateSchools]: Des_GraduateSchools,
    [R_BuildingType.Factories]: Des_Factories,
    /*****************/
    /*ATOMIC*/
    /*****************/
    /*FOOD*/
    [R_BuildingType.IndustrialFarms]: Des_IndustrialFarms,
    /*WATER*/
    [R_BuildingType.UrbanWaterNetworks]: Des_UrbanWaterNetworks,
    /*POPULATION*/
    [R_BuildingType.Metropolises]: Des_Metropolises,
    /*ENERGY*/
    [R_BuildingType.NuclearPower]: Des_NuclearPower,
    /*ORE*/
    /*SCIENCE*/
    /*****************/
    /*NEXUS*/
    /*****************/
    /*FOOD*/
    [R_BuildingType.GMOFarming]: placeholder,
    [R_BuildingType.AquaticFarming]: placeholder,
    /*WATER*/
    [R_BuildingType.DesalinationPlants]: placeholder,
    /*POPULATION*/
    /*ENERGY*/
    [R_BuildingType.SolarPanels]: placeholder,
    [R_BuildingType.WindTurbines]: placeholder,
    [R_BuildingType.GeothermalEnergy]: placeholder,
    /*ORE*/
    [R_BuildingType.TrenchMining]: placeholder,
    [R_BuildingType.ChemicalMining]: placeholder,
    /*SCIENCE*/
    [R_BuildingType.Electronics]: placeholder,
    [R_BuildingType.Computers]: placeholder,
    [R_BuildingType.Cyberspace]: placeholder,
    /*****************/
    /*GALAXTIC*/
    /*****************/
    /*FOOD*/
    [R_BuildingType.VerticalFarming]: placeholder,
    [R_BuildingType.BioEngineeredMeat]: placeholder,
    [R_BuildingType.SyntheticFoodCreation]: placeholder,
    /*WATER*/
    [R_BuildingType.AutomatedWaterNetworks]: placeholder,
    [R_BuildingType.AtmosphericWaterExtractors]: placeholder,
    [R_BuildingType.OrbitalWaterHarvesting]: placeholder,
    /*POPULATION*/
    [R_BuildingType.CyberCities]: placeholder,
    [R_BuildingType.VerticalMetropolises]: placeholder,
    [R_BuildingType.Aquapolis]: placeholder,
    /*ENERGY*/
    [R_BuildingType.FusionPower]: placeholder,
    [R_BuildingType.OrbitalSolarEnergy]: placeholder,
    [R_BuildingType.MarineHydrokineticPower]: placeholder,
    /*ORE*/
    [R_BuildingType.AutonomousMining]: placeholder,
    [R_BuildingType.AsteroidMining]: placeholder,
    /*SCIENCE*/
    [R_BuildingType.ArtificialIntelligence]: placeholder,
  },
  [Planet.Hollow]: {
    /*ANCIENT*/
    /*****************/
    /*FOOD*/
    [R_BuildingType.HuntingAndGathering]: Hol_HuntingAndGathering,
    [R_BuildingType.SubsistenceFarms]: Hol_SubsistenceFarms,
    [R_BuildingType.PastoralFarms]: Hol_PastoralFarms,
    /*WATER*/
    [R_BuildingType.NaturalWaterways]: Hol_NaturalWaterways,
    [R_BuildingType.RainwaterHarvesting]: Hol_RainwaterHarvesting,
    /*POPULATION*/
    [R_BuildingType.NomadicTribes]: Hol_NomadicTribes,
    [R_BuildingType.Hamlets]: Hol_Hamlets,
    /*ENERGY*/
    [R_BuildingType.BiofuelBurning]: Hol_BiofuelBurning,
    /*ORE*/
    [R_BuildingType.SurfaceMining]: Hol_SurfaceMining,
    /*SCIENCE*/
    /*****************/
    /*IMPERIAL*/
    /*****************/
    /*FOOD*/
    [R_BuildingType.MixedFarms]: Hol_MixedFarms,
    /*WATER*/
    [R_BuildingType.Wells]: Hol_Wells,
    [R_BuildingType.Cisterns]: Hol_Cisterns,
    [R_BuildingType.Aqueducts]: Hol_Aqueducts,
    /*POPULATION*/
    [R_BuildingType.Villages]: Hol_Villages,
    [R_BuildingType.ProtoCities]: Hol_ProtoCities,
    /*ENERGY*/
    [R_BuildingType.Windmills]: Hol_Windmills,
    [R_BuildingType.Watermills]: Hol_Watermills,
    /*ORE*/
    [R_BuildingType.AquaGem]: placeholder,
    [R_BuildingType.ArcaneRelic]: Hol_ArcaneRelic,
    [R_BuildingType.Biomass]: placeholder,
    [R_BuildingType.CryoCrystal]: placeholder,
    [R_BuildingType.Helium3]: placeholder,
    [R_BuildingType.Magmacite]: placeholder,
    [R_BuildingType.Solarite]: placeholder,
    /*SCIENCE*/
    [R_BuildingType.Schools]: Hol_Schools,
    [R_BuildingType.Universities]: Hol_Universities,
    /*****************/
    /*INDUSTIAL*/
    /*****************/
    /*FOOD*/
    [R_BuildingType.CommercialFarms]: Hol_CommercialFarms,
    /*WATER*/
    [R_BuildingType.Reservoirs]: Hol_Reservoirs,
    [R_BuildingType.AquiferAccessPoints]: Hol_AquiferAccessPoints,
    /*POPULATION*/
    [R_BuildingType.Cities]: Hol_Cities,
    /*ENERGY*/
    [R_BuildingType.CoalAndSteamPower]: placeholder,
    [R_BuildingType.CrudeOilAndGasEnergy]: Hol_CrudeOilAndGasEnergy,
    [R_BuildingType.HydroelectricPower]: placeholder,
    /*ORE*/
    [R_BuildingType.SubterraneanMining]: placeholder,
    [R_BuildingType.Quarrying]: Hol_Quarrying,
    /*SCIENCE*/
    [R_BuildingType.GraduateSchools]: Hol_GraduateSchools,
    [R_BuildingType.Factories]: Hol_Factories,
    /*****************/
    /*ATOMIC*/
    /*****************/
    /*FOOD*/
    [R_BuildingType.IndustrialFarms]: Hol_IndustrialFarms,
    /*WATER*/
    [R_BuildingType.UrbanWaterNetworks]: Hol_UrbanWaterNetworks,
    /*POPULATION*/
    [R_BuildingType.Metropolises]: Hol_Metropolises,
    /*ENERGY*/
    [R_BuildingType.NuclearPower]: Hol_NuclearPower,
    /*ORE*/
    /*SCIENCE*/
    /*****************/
    /*NEXUS*/
    /*****************/
    /*FOOD*/
    [R_BuildingType.GMOFarming]: placeholder,
    [R_BuildingType.AquaticFarming]: placeholder,
    /*WATER*/
    [R_BuildingType.DesalinationPlants]: placeholder,
    /*POPULATION*/
    /*ENERGY*/
    [R_BuildingType.SolarPanels]: placeholder,
    [R_BuildingType.WindTurbines]: placeholder,
    [R_BuildingType.GeothermalEnergy]: placeholder,
    /*ORE*/
    [R_BuildingType.TrenchMining]: placeholder,
    [R_BuildingType.ChemicalMining]: placeholder,
    /*SCIENCE*/
    [R_BuildingType.Electronics]: placeholder,
    [R_BuildingType.Computers]: placeholder,
    [R_BuildingType.Cyberspace]: placeholder,
    /*****************/
    /*GALAXTIC*/
    /*****************/
    /*FOOD*/
    [R_BuildingType.VerticalFarming]: placeholder,
    [R_BuildingType.BioEngineeredMeat]: placeholder,
    [R_BuildingType.SyntheticFoodCreation]: placeholder,
    /*WATER*/
    [R_BuildingType.AutomatedWaterNetworks]: placeholder,
    [R_BuildingType.AtmosphericWaterExtractors]: placeholder,
    [R_BuildingType.OrbitalWaterHarvesting]: placeholder,
    /*POPULATION*/
    [R_BuildingType.CyberCities]: placeholder,
    [R_BuildingType.VerticalMetropolises]: placeholder,
    [R_BuildingType.Aquapolis]: placeholder,
    /*ENERGY*/
    [R_BuildingType.FusionPower]: placeholder,
    [R_BuildingType.OrbitalSolarEnergy]: placeholder,
    [R_BuildingType.MarineHydrokineticPower]: placeholder,
    /*ORE*/
    [R_BuildingType.AutonomousMining]: placeholder,
    [R_BuildingType.AsteroidMining]: placeholder,
    /*SCIENCE*/
    [R_BuildingType.ArtificialIntelligence]: placeholder,
  },
};

const enumToImage = <T>(enumType: T, value: T[keyof T], imageType: ImageType = ImageType.Raw, planet: Planet | undefined = undefined): string => {
  let _image = placeholder;
  switch (enumType) {
    case Splash:
      _image = mapSplash[value as Splash] || placeholder;
      break;
    case GlobalIcon:
      _image = mapGlobalIcon[value as GlobalIcon] || placeholder;
      break;
    case MenuButtons:
      _image = menuButtons[value as MenuButtons] || placeholder;
      break;
    case BG_Planet:
      _image = mapBGPlanet[value as BG_Planet] || placeholder;
      break;
    case Planet:
      switch (imageType) {
        default:
          _image = mapPlanet[value as Planet] || placeholder;
          break;
      }
      break;
    case DiurnalCycle:
      _image = diurnalCycle[value as DiurnalCycle] || placeholder;
      break;
    case WeatherEvent:
      _image = mapWeather[value as WeatherEvent] || placeholder;
      break;
    case Race:
      _image = races[value as Race] || placeholder;
      break;
    case Morale:
      _image = morale[value as Morale] || placeholder;
      break;
    case Resource:
      _image = mapResource[value as Resource] || placeholder;
      break;
    case R_BuildingType:
      if (planet === undefined) break;
      _image = buildings[planet][value as R_BuildingType] || placeholder;
      break;
    default:
      break;
  }

  return _image;
};

export default enumToImage;
