import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Text,
  Heading,
  Input,
  Button,
  Divider,
  Link,
  InputGroup,
  InputRightElement,
  Checkbox,
  useToast,
  HStack,
  useDisclosure,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Stack,
} from "@chakra-ui/react";
import { ChangeEvent, useEffect, useState } from "react";
import enumToImage from "../services/image";
import { Splash } from "../structs/Enums";
import api from "../services/api";
import { baseUrl, path, version } from "../global";
import { useNavigate } from "react-router-dom";
import Token from "../services/token";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { FaGoogle } from "react-icons/fa";
import GameButton from "../components/control/GameButton";

const CustomInput = ({ label, value, onChange, type = "text", id, autoFocus, isPassword, visible, setVisible }: any) => (
  <FormControl mt={5} mb={5}>
    <FormLabel htmlFor={id}>{label}</FormLabel>
    <InputGroup size="md">
      <Input autoFocus={autoFocus} value={value} onChange={onChange} type={visible ? "text" : type} id={id} required />
      {isPassword && (
        <InputRightElement onClick={() => setVisible(!visible)} cursor="pointer">
          {visible ? (
            <svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" width="20" height="20">
              <path
                fill="currentColor"
                d="M572.52 241.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400a144 144 0 1 1 144-144 143.93 143.93 0 0 1-144 144zm0-240a95.31 95.31 0 0 0-25.31 3.79 47.85 47.85 0 0 1-66.9 66.9A95.78 95.78 0 1 0 288 160z"
              ></path>
            </svg>
          ) : (
            <svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" width="20" height="20">
              <path
                fill="currentColor"
                d="M320 400c-75.85 0-137.25-58.71-142.9-133.11L72.2 185.82c-13.79 17.3-26.48 35.59-36.72 55.59a32.35 32.35 0 0 0 0 29.19C89.71 376.41 197.07 448 320 448c26.91 0 52.87-4 77.89-10.46L346 397.39a144.13 144.13 0 0 1-26 2.61zm313.82 58.1l-110.55-85.44a331.25 331.25 0 0 0 81.25-102.07 32.35 32.35 0 0 0 0-29.19C550.29 135.59 442.93 64 320 64a308.15 308.15 0 0 0-147.32 37.7L45.46 3.37A16 16 0 0 0 23 6.18L3.37 31.45A16 16 0 0 0 6.18 53.9l588.36 454.73a16 16 0 0 0 22.46-2.81l19.64-25.27a16 16 0 0 0-2.82-22.45zm-183.72-142l-39.3-30.38A94.75 94.75 0 0 0 416 256a94.76 94.76 0 0 0-121.31-92.21A47.65 47.65 0 0 1 304 192a46.64 46.64 0 0 1-1.54 10l-73.61-56.89A142.31 142.31 0 0 1 320 112a143.92 143.92 0 0 1 144 144c0 21.63-5.29 41.79-13.9 60.11z"
              ></path>
            </svg>
          )}
        </InputRightElement>
      )}
    </InputGroup>
  </FormControl>
);

const SignUp = ({ view, focus, username, onChangeUsername, password, onChangePassword, visible, setVisible, valid, email, onChangeEmail, signup, googleSignup }: any) =>
  view === "signup" && (
    <Box>
      <Heading>SIGN UP</Heading>
      <CustomInput label="Username" value={username} onChange={onChangeUsername} autoFocus={focus === "username"} id="username" />
      <CustomInput label="Password" value={password} onChange={onChangePassword} type="password" id="password" autoFocus={focus === "password"} isPassword={true} visible={visible} setVisible={setVisible} />
      <Text fontSize="sm" color={valid ? "" : "red"} mb={5}>
        &#x2714;At least 10 characters with an uppercase letter and a number.
      </Text>
      <CustomInput label="Email" value={email} onChange={onChangeEmail} type="email" id="email" autoFocus={focus === "email"} />
      <Text fontSize="xs" mb={5}>
        *used for password recovery and updates + news about Celestial Regime
      </Text>
      <Button w="full" onClick={signup}>
        Sign up
      </Button>
      <Button w="full" leftIcon={<FaGoogle />} mt={5} onClick={googleSignup}>
        Sign up with Google
      </Button>
    </Box>
  );

const SignIn = ({ view, focus, username, onChangeUsername, password, onChangePassword, visible, setVisible, remember, onClickRemember, signin, googleSignin, setView }: any) =>
  view === "signin" && (
    <Box>
      <Heading>SIGN IN</Heading>
      <CustomInput label="Username" value={username} onChange={onChangeUsername} autoFocus={focus === "username"} id="username" />
      <CustomInput label="Password" value={password} onChange={onChangePassword} type="password" id="password" autoFocus={focus === "password"} isPassword={true} visible={visible} setVisible={setVisible} />
      <Flex justifyContent="flex-end">
        <Link fontSize="sm" onClick={() => setView("reset")}>
          Reset password
        </Link>
      </Flex>
      <Checkbox isChecked={remember} autoFocus={focus === "remember"} onChange={onClickRemember} defaultChecked id="remember" w="full" mt={5}>
        Remember Me
      </Checkbox>
      <Button w="full" mt={5} onClick={signin}>
        Sign in
      </Button>
      <Button w="full" mt={5} leftIcon={<FaGoogle />} onClick={googleSignin}>
        Sign in with Google
      </Button>
    </Box>
  );

const Reset = ({ view, focus, email, onChangeEmail, sendCode, setView }: any) =>
  view === "reset" && (
    <Box>
      <Flex justifyContent="flex-start">
        <Link fontSize="sm" onClick={() => setView("signin")}>
          Back
        </Link>
      </Flex>
      <Heading>RESET PASSWORD</Heading>
      <CustomInput label="Email" value={email} onChange={onChangeEmail} type="email" id="email" autoFocus={focus === "email"} />
      <Button w="full" mt={5} onClick={sendCode}>
        Next
      </Button>
    </Box>
  );

const Confirm = ({ view, focus, code, onChangeCode, confirmCode, setView }: any) => {
  useEffect(() => {
    if (focus.startsWith("code_")) {
      const index = parseInt(focus.split("_")[1], 10);
      const input = document.getElementById(`code_${index}`) as HTMLInputElement;
      if (input) {
        setTimeout(() => {
          input.focus();
        }, 0);
      }
    }
  }, [focus]);

  return (
    view === "confirm" && (
      <Box>
        <Flex justifyContent="flex-start">
          <Link fontSize="sm" onClick={() => setView("reset")}>
            Back
          </Link>
        </Flex>
        <Heading>RESET PASSWORD</Heading>
        <FormControl mt={5} mb={5}>
          <FormLabel fontSize="xs">Check your email for the security code.</FormLabel>
          <FormLabel htmlFor="code_1">Security code</FormLabel>
          <HStack>
            {[...Array(6)].map((_, i) => (
              <Input key={i + 1} textAlign="center" id={`code_${i + 1}`} value={code[`${i + 1}`]} onChange={(e) => onChangeCode(e, i + 1)} type="number" required />
            ))}
          </HStack>
        </FormControl>
        <Button w="full" mt={5} onClick={confirmCode}>
          Confirm code
        </Button>
      </Box>
    )
  );
};

const Change = ({ view, focus, password, onChangePassword, visible, setVisible, valid, change }: any) =>
  view === "change" && (
    <Box>
      <Heading>SET A NEW PASSWORD</Heading>
      <CustomInput label="Password" value={password} onChange={onChangePassword} type="password" id="password" autoFocus={focus === "password"} isPassword={true} visible={visible} setVisible={setVisible} />
      <Text fontSize="sm" color={valid ? "" : "red"} mb={5}>
        &#x2714;At least 10 characters with an uppercase letter and a number.
      </Text>
      <Button w="full" mt={5} onClick={change}>
        Change password
      </Button>
    </Box>
  );

const Landing = () => {
  const token = new Token();
  const navigation = useNavigate();
  const toast = useToast();

  const [view, setView] = useState(token.signin() ? "signin" : "signup");
  const [visible, setVisible] = useState(false);
  const [valid, setValid] = useState(true);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [remember, setRemember] = useState(true);
  const [focus, setFocus] = useState("username");

  const [code, setCode] = useState({ 1: "", 2: "", 3: "", 4: "", 5: "", 6: "" });

  const { isOpen, onOpen, onClose } = useDisclosure();

  const onChangeUsername = (e: ChangeEvent<HTMLInputElement>) => {
    setUsername(e.target.value);
    setFocus("username");
  };

  const onChangePassword = (e: ChangeEvent<HTMLInputElement>) => {
    const min = 10;
    const upp = /[A-Z]/;
    const num = /\d/;

    if (e.target.value.length === 0) setValid(true);
    else if (e.target.value.length < min) setValid(false);
    else if (!upp.test(e.target.value)) setValid(false);
    else if (!num.test(e.target.value)) setValid(false);
    else setValid(true);

    setPassword(e.target.value);
    setFocus("password");
  };

  const onChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setFocus("email");
  };

  const onClickRemember = (e: ChangeEvent<HTMLInputElement>) => {
    setRemember(e.target.checked);
    setFocus("remember");
  };

  const onClickView = (value: string) => {
    setUsername("");
    setPassword("");
    setEmail("");
    setRemember(true);

    setView(value);
    setFocus("username");
  };

  const onChangeCode = (e: ChangeEvent<HTMLInputElement>, i: number) => {
    const value = e.target.value;

    setFocus(`code_${i === 6 ? i : i + 1}`);
    setCode((prev) => ({ ...prev, [`${i}`]: value.length ? value[0] : "" }));
  };

  const onGoogleLoginHandler = useGoogleLogin({
    onSuccess: (codeResponse) => {
      axios
        .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${codeResponse.access_token}`, {
          headers: {
            Authorization: `Bearer ${codeResponse.access_token}`,
            Accept: "application/json",
          },
        })
        .then(async (res) => {
          const response = await api({
            url: `${baseUrl}/api/account/register`,
            content: { ...res.data, username },
            post: true,
            headers: {},
            token: "",
          });

          if (response.ok) {
            token.set(response.data.content);
            navigation(path("start"));
          } else if (response.status === 409) {
            toast({
              title: "An account with this username already exists.",
              status: "warning",
              isClosable: true,
            });
          } else {
            toast({
              title: "Registration failed. Please try again.",
              status: "error",
              isClosable: true,
            });
          }
        })
        .catch((err) => console.error(err));
    },
    onError: (error) => console.error("Login Failed:", error),
  });
  const googleSignup = () => {
    if (!username.length) {
      toast({
        title: "Please enter a username to continue.",
        status: "warning",
        isClosable: true,
      });
    } else {
      onGoogleLoginHandler();
    }
  };
  const signup = async () => {
    if (!username.length) {
      toast({
        title: "Please enter a username to continue.",
        status: "warning",
        isClosable: true,
      });
    } else if (!password.length) {
      toast({
        title: "Please enter a password to continue.",
        status: "warning",
        isClosable: true,
      });
    } else if (!valid) {
      toast({
        title: "Please enter a valid password to continue.",
        status: "warning",
        isClosable: true,
      });
    } else if (!email.length) {
      toast({
        title: "Please enter an email address to continue.",
        status: "warning",
        isClosable: true,
      });
    } else {
      const response = await api({
        url: `${baseUrl}/api/account/register`,
        content: { username, password, email },
        post: true,
        headers: {},
        token: "",
      });
      if (response.ok) {
        token.set(response.data.content);
        navigation(path("start"));
      } else if (response.status === 409) {
        toast({
          title: "An account with this username already exists.",
          status: "warning",
          isClosable: true,
        });
      } else {
        toast({
          title: "Registration failed. Please try again.",
          status: "error",
          isClosable: true,
        });
      }
    }
  };

  const googleSignin = useGoogleLogin({
    onSuccess: (codeResponse) => {
      axios
        .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${codeResponse.access_token}`, {
          headers: {
            Authorization: `Bearer ${codeResponse.access_token}`,
            Accept: "application/json",
          },
        })
        .then(async (res) => {
          const response = await api({
            url: `${baseUrl}/api/account/login`,
            content: res.data,
            post: true,
            headers: {},
            token: "",
          });

          if (response.ok) {
            token.set(response.data.content, remember);
            navigation(path("home"));
          } else {
            toast({
              title: "Unable to join the game. Please try again.",
              status: "error",
              isClosable: true,
            });
          }
        })
        .catch((err) => console.error(err));
    },
    onError: (error) => console.error("Login Failed:", error),
  });
  const signin = async () => {
    if (!username?.length) {
      toast({
        title: "Please enter a username to continue.",
        status: "warning",
        isClosable: true,
      });
    } else if (!password?.length) {
      toast({
        title: "Please enter a password to continue.",
        status: "warning",
        isClosable: true,
      });
    } else {
      const response = await api({
        url: `${baseUrl}/api/account/login`,
        content: { username, password },
        post: true,
        headers: {},
        token: "",
      });

      if (response.ok) {
        token.set(response.data.content, remember);
        navigation(path("home"));
      } else {
        toast({
          title: "Unable to join the game. Please try again.",
          status: "error",
          isClosable: true,
        });
      }
    }
  };

  const sendCode = async () => {
    const response = await api({
      url: `${baseUrl}/api/account/reset`,
      content: { email },
      post: true,
      headers: {},
      token: "",
    });
    if (response.ok) {
      setCode({ 1: "", 2: "", 3: "", 4: "", 5: "", 6: "" });
      setFocus("code_1");
      setView("confirm");
    }
  };

  const confirmCode = async () => {
    const response = await api({
      url: `${baseUrl}/api/account/confirm`,
      content: { email, code: `${code[1]}${code[2]}${code[3]}${code[4]}${code[5]}${code[6]}` },
      post: true,
      headers: {},
      token: "",
    });
    if (response.ok) {
      setView("change");
    } else {
      toast({
        title: "The code you entered is invalid. Please try again.",
        status: "error",
        isClosable: true,
      });
    }
  };

  const change = async () => {
    if (!password.length) {
      toast({
        title: "Please enter a password to continue.",
        status: "warning",
        isClosable: true,
      });
    } else if (!valid) {
      toast({
        title: "Please enter a valid password to continue.",
        status: "warning",
        isClosable: true,
      });
    } else {
      const response = await api({
        url: `${baseUrl}/api/account/change`,
        content: { email, code: `${code[1]}${code[2]}${code[3]}${code[4]}${code[5]}${code[6]}`, password },
        post: true,
        headers: {},
        token: "",
      });
      if (response.ok) {
        setUsername("");
        setPassword("");
        setEmail("");
        setRemember(true);

        setView("signin");
        setFocus("username");
      } else {
        toast({
          title: "Failed to update your password. Please try again.",
          status: "error",
          isClosable: true,
        });
      }
    }
  };

  useEffect(() => {
    document.body.style.backgroundImage = `url(${enumToImage(Splash, Splash.Landing)})`;
    document.body.style.backgroundSize = "cover";
    document.body.style.backgroundPosition = "center";
    document.body.style.backgroundAttachment = "fixed";

    //const isFirefoxBrowser = navigator.userAgent.toLowerCase().indexOf("firefox") > -1;
    const isFirefoxBrowser = false;
    if (isFirefoxBrowser) {
      localStorage.removeItem("token");
      onOpen();
    }

    if (token.get()) {
      navigation(path("home"));
    }
  }, []);

  return (
    <Flex h="95dvh" alignItems="center" justifyContent="center">
      <Box flex="1" bg="space.600" className="landing">
        <Flex direction="row" minH="45rem">
          <Box flex="1" h={800} maxW="55%" w="full">
            <Flex direction="column" p={10} h="full">
              <Box flex="1">
                <SignUp {...{ view, focus, username, onChangeUsername, password, onChangePassword, visible, setVisible, valid, email, onChangeEmail, signup, googleSignup }} />
                <SignIn {...{ view, focus, username, onChangeUsername, password, onChangePassword, visible, setVisible, remember, onClickRemember, signin, googleSignin, setView }} />
                <Reset {...{ view, focus, email, onChangeEmail, sendCode, setView }} />
                <Confirm {...{ view, focus, code, onChangeCode, confirmCode, setView }} />
                <Change {...{ view, focus, password, onChangePassword, visible, setVisible, valid, change }} />
              </Box>
              <Box flex="0 1 auto">
                <Box position="relative" padding="10">
                  <Divider />
                </Box>
              </Box>
              <Box flex="0 1 auto">
                {view === "reset" || view === "confirm" || view === "change" ? (
                  <Text></Text>
                ) : (
                  <Text>
                    {view === "signup" ? "Already have an account?" : "Don’t have an account?"}&nbsp;
                    <Link onClick={() => onClickView(view === "signup" ? "signin" : "signup")}>{view === "signup" ? "Sign in" : "Sign up"}</Link>
                  </Text>
                )}
                <Text fontSize="sm" mt={10}>
                  ©2024 coettools. All rights reserved.
                </Text>
              </Box>
            </Flex>
          </Box>
          <Box flex="1" maxW="45%" w="100%" backgroundImage={enumToImage(Splash, Splash.Creating)}>
            <Heading mt={40}>Celestial Regime</Heading>
            <Heading fontSize="lg">
              Pre-alpha{" "}
              <Box as="span" fontSize="xs">
                ({version})
              </Box>
            </Heading>
          </Box>
        </Flex>
      </Box>
      <Modal closeOnOverlayClick={false} isCentered isOpen={isOpen} onClose={onClose} size="2xl">
        <ModalContent bg="space.600">
          <ModalHeader>
            <Heading size="md">Warning: Unsupported Browser</Heading>
          </ModalHeader>
          <ModalBody>
            <Text>Firefox is not currently supported, which may affect your overall experience.</Text>
            <Text>For the best experience, we recommend using a Chrome-based browser.</Text>
          </ModalBody>
          <ModalFooter>
            <Stack direction="row" spacing={1}>
              <GameButton onClick={onClose}>OK</GameButton>
            </Stack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default Landing;
