type TokenContent = {
  value: string;
  expiry: number;
};

class Token {
  private readonly _HOUR_TO_MILLI: number = 3600000;
  private readonly _30_DAY_TO_MILLI: number = 2592000000;

  constructor() {}
  #signin = (): void => {
    const now = new Date();
    localStorage.setItem("signin", now.getTime().toString());
  };

  get = (): string | null => {
    const json = localStorage.getItem("token");
    if (json === null) return null;

    const token: TokenContent = JSON.parse(json);
    const now = new Date();

    if (now.getTime() > token.expiry) {
      localStorage.removeItem("token");
      return null;
    }

    return token.value;
  };
  set = (value: string, remember: boolean = false): void => {
    const now = new Date();
    const token: TokenContent = {
      value: value,
      expiry: now.getTime() + (remember ? this._30_DAY_TO_MILLI : this._HOUR_TO_MILLI),
    };

    localStorage.setItem("token", JSON.stringify(token));
    this.#signin();
  };

  signin = (): boolean => {
    const val = localStorage.getItem("signin");
    if (val === null) return false;

    return true;
  };
}

export default Token;
