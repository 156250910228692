import { create } from "zustand";
import { subscribeWithSelector } from "zustand/middleware";
import Volume from "../audio/volume";

type PlayFunction = void;
type ExposedData = {
  stop: () => void;
  pause: () => void;
  sound: {
    volume: (volume: number) => void;
  };
};
type Sound = [PlayFunction, ExposedData] | undefined;

type StoreSettings = {
  sound: Sound | undefined;
  setSound: (sound: Sound) => void;
  volumeMaster: number;
  setVolumeMaster: (volumeMaster: number) => void;
  volumeMusic: number;
  setVolumeMusic: (volumeMusic: number) => void;
  volumeSFX: number;
  setVolumeSFX: (volumeSFX: number) => void;
  volumeVoice: number;
  setVolumeVoice: (volumeVoice: number) => void;
};

const volume = new Volume();

const master = volume.master;
const music = volume.music * master;
const sfx = volume.sfx * master;
const voice = volume.voice * master;

const useStoreSettings = create<StoreSettings, [["zustand/subscribeWithSelector", never]]>(
  subscribeWithSelector<StoreSettings>((set) => ({
    sound: undefined,
    setSound: (sound) => set({ sound }),
    volumeMaster: master,
    setVolumeMaster: (volumeMaster) => {
      volume.master = volumeMaster;
      set({ volumeMaster: volume.master });
    },
    volumeMusic: music,
    setVolumeMusic: (volumeMusic) => {
      volume.music = volumeMusic;
      set({ volumeMusic: volume.music });
    },
    volumeSFX: sfx,
    setVolumeSFX: (volumeSFX) => {
      volume.sfx = volumeSFX;
      set({ volumeSFX: volume.sfx });
    },
    volumeVoice: voice,
    setVolumeVoice: (volumeVoice) => {
      volume.voice = volumeVoice;
      set({ volumeVoice: volume.voice });
    },
  }))
);

export default useStoreSettings;
