import { motion } from "framer-motion";
import { Box } from "@chakra-ui/react";
import { Civilization } from "./structs/Enums";

/*x.=major, x.x=minor, x.x.x=patch, x.x.x.x.x=mmdd*/
export const version = import.meta.env.VITE_VERSION;
export const baseUrl = import.meta.env.VITE_API_URL;
export const baseWSS = import.meta.env.VITE_WSS_URL;
export const toUTCDateString = (utc: string) => {
  const date = new Date(utc);

  const y = date.getFullYear().toString().padStart(4, "0");
  const m = (date.getMonth() + 1).toString().padStart(2, "0");
  const d = date.getDate().toString().padStart(2, "0");
  const hh = date.getHours().toString().padStart(2, "0");
  const mm = date.getMinutes().toString().padStart(2, "0");
  const ss = date.getSeconds().toString().padStart(2, "0");

  return `${m}/${d}/${y} ${hh}:${mm}:${ss}`;
};
const toTimeObj = (seconds: number) => {
  const pad = (value: number) => {
    return value.toString().padStart(2, "0");
  };

  seconds = seconds < 0 ? 0 : seconds;

  const dd = Math.floor(seconds / (3600 * 24));
  const hh = Math.floor((seconds % (3600 * 24)) / 3600);
  const mm = Math.floor((seconds % 3600) / 60);
  const ss = Math.round(seconds % 60);

  return {
    dd: {
      raw: dd,
      clean: pad(dd),
    },
    hh: {
      raw: hh,
      clean: pad(hh),
    },
    mm: {
      raw: mm,
      clean: pad(mm),
    },
    ss: {
      raw: ss,
      clean: pad(ss),
    },
  };
};
export const toTimeStr = (seconds: number) => {
  const time = toTimeObj(seconds);

  if (time.dd.raw > 0) {
    return `${time.dd.raw}d ${time.hh.raw}h ${time.mm.raw}m ${time.ss.raw}s`;
  } else if (time.hh.raw > 0) {
    return `${time.hh.raw}h ${time.mm.raw}m ${time.ss.raw}s`;
  } else if (time.mm.raw > 0) {
    return `${time.mm.raw}m ${time.ss.raw}s`;
  } else {
    return `${time.ss.raw}s`;
  }
};

export const toNumberStr = (value: number) => {
  return new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  }).format(value);
};
export const toNumberShort = (value: number) => {
  if (value < 1000) return value.toString();
  else if (value < 1000000) return (Math.floor(value / 100) / 10).toFixed(1).replace(/\.0$/, "") + "k";
  else return (Math.floor(value / 100000) / 10).toFixed(1).replace(/\.0$/, "") + "M";
};
export const path = (target: string) => {
  const root = "/g";
  switch (target.toLowerCase()) {
    case "login":
      return "/";
    case "start":
    case "nps":
      return "/nps";
    case "home":
      return `${root}/h`;
    case "osos":
      return `${root}/osos`;
    default:
      return root;
  }
};
export const logout = () => {
  localStorage.removeItem("token");
  window.location.href = "/";
};
export const full = () => {
  window.location.href = "/npa";
};
export const start = () => {
  window.location.href = "/nps";
};

export const MotionBox = motion(Box);

export const civilization = (building: Civilization, planet: Civilization) => {
  switch (planet) {
    case Civilization.Ancient:
      return [Civilization.Ancient].includes(building);
    case Civilization.Imperial:
      return [Civilization.Ancient, Civilization.Imperial].includes(building);
    case Civilization.Industrial:
      return [Civilization.Ancient, Civilization.Imperial, Civilization.Industrial].includes(building);
    case Civilization.Atomic:
      return [Civilization.Ancient, Civilization.Imperial, Civilization.Industrial, Civilization.Atomic].includes(building);
    case Civilization.Nexus:
      return [Civilization.Ancient, Civilization.Imperial, Civilization.Industrial, Civilization.Atomic, Civilization.Nexus].includes(building);
    case Civilization.Galactic:
      return [Civilization.Ancient, Civilization.Imperial, Civilization.Industrial, Civilization.Atomic, Civilization.Nexus, Civilization.Galactic].includes(building);
    default:
      return true;
  }
};
type AspectRation = {
  ratio: "16:9" | "4:3" | "1:1" | "9:16" | "3:4";
  w?: number;
  h?: number;
};
export type Dimensions = {
  w: number;
  h: number;
};

export const dimensions = (ar: AspectRation): Dimensions => {
  const { ratio, w, h } = ar;

  switch (ratio) {
    case "16:9":
      if (w !== undefined) {
        return { w, h: (w / 16) * 9 };
      } else if (h !== undefined) {
        return { w: (h / 9) * 16, h };
      }
      break;
    case "4:3":
      if (w !== undefined) {
        return { w, h: (w / 4) * 3 };
      } else if (h !== undefined) {
        return { w: (h / 3) * 4, h };
      }
      break;
    case "1:1":
      if (w !== undefined) {
        return { w, h: w };
      } else if (h !== undefined) {
        return { w: h, h };
      }
      break;
    case "9:16":
      if (w !== undefined) {
        return { w, h: (w / 9) * 16 };
      } else if (h !== undefined) {
        return { w: (h / 16) * 9, h };
      }
      break;
    case "3:4":
      if (w !== undefined) {
        return { w, h: (w / 3) * 4 };
      } else if (h !== undefined) {
        return { w: (h / 4) * 3, h };
      }
      break;
  }

  return { w: 0, h: 0 };
};

export const IMG_RC_L: Dimensions = { w: 42, h: 42 };
export const IMG_RC_M: Dimensions = { w: 32, h: 32 };
export const IMG_RC_S: Dimensions = { w: 25, h: 25 };
export const IMG_STD_L: Dimensions = { w: 50, h: 50 };
