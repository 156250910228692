import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";

import { ChakraProvider, ColorModeScript } from "@chakra-ui/react";

import routes from "./routes.tsx";

import "./index.css";
import themeCUI from "./theme-cui.ts";

import { PostHogProvider } from "posthog-js/react";
import { GoogleOAuthProvider } from "@react-oauth/google";
const options = {
  api_host: import.meta.env.VITE_API_POSTHOG_HOST,
};

ReactDOM.createRoot(document.getElementById("root")!).render(
  <PostHogProvider apiKey={import.meta.env.VITE_API_POSTHOG_KEY} options={options}>
    <GoogleOAuthProvider clientId={import.meta.env.VITE_API_OAUTH_CLIENTID}>
      <ChakraProvider theme={themeCUI}>
        <ColorModeScript initialColorMode={themeCUI.config.initialColorMode} />
        <RouterProvider router={routes} />
      </ChakraProvider>
    </GoogleOAuthProvider>
  </PostHogProvider>
);
