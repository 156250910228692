import { Box, Button, Flex, Grid, Heading } from "@chakra-ui/react";
import SolarSystem from "../maps/SolarSystem";
import useStoreUniverse from "../../../services/stores/useStoreUniverse";
import { useState } from "react";
import useStoreGameState from "../../../services/stores/useStoreGameState";
import ClientUpdate from "../../../structs/client-update";
import { ClientUpdate as enumClientUpdate } from "../../../structs/Enums";

const OSOS = () => {
  const webWorker = useStoreGameState((state) => state.webWorker);
  const currPlanet = useStoreUniverse((state) => state.currPlanet);
  const solarSystem = useStoreUniverse((state) => state.solarSystem);

  const [isScan, setScan] = useState(false);

  const onClick = () => {
    if (currPlanet && webWorker) {
      setScan(true);
      webWorker.send(
        new ClientUpdate(enumClientUpdate.OSOS_Scan, {
          planetId: currPlanet.id,
        }),
        () => setScan(false)
      );
    }
  };
  if (!solarSystem) return <div />;

  return (
    <Flex direction="column" height="100%" p={4} gap={4}>
      <Box flex="0 1 auto">
        <Box w="full" p={4}>
          <Heading>Outer Space Observation System</Heading>
        </Box>
      </Box>
      <Box flex="1" overflow="hidden">
        <Box p={0} bg="blackAlpha.700" borderRadius="md" textAlign="left" height="100%" overflow="auto">
          <SolarSystem planets={solarSystem?.planets} />
        </Box>
      </Box>
      <Box flex="0 1 auto">
        <Box p={2} bg="blackAlpha.700" borderRadius="md">
          <Grid templateColumns="repeat(3, 1fr)" gap={6}>
            {true ? (
              <Box>&nbsp;</Box>
            ) : isScan ? (
              <Button size={{ base: "xs", "2xl": "sm" }} variant="solid" isLoading loadingText="SCANNING..."></Button>
            ) : (
              <Button size={{ base: "xs", "2xl": "sm" }} variant="solid" onClick={onClick} isDisabled={isScan}>
                SCAN OUTER SPACE
              </Button>
            )}
          </Grid>
        </Box>
      </Box>
    </Flex>
  );
};

export default OSOS;
