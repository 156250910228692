import { useEffect, useState } from "react";
import useDebounce from "../../services/useDebounce";

type Props<T> = {
  data?: T[];
  rows?: number;
};
type DataTable<T> = {
  dataTable: T[];
  pageCount: number;
  page: number;
  prev: () => void;
  next: () => void;
};

const useDataTable = <T>({ data = [], rows = 10 }: Props<T>): DataTable<T> => {
  data = data ?? [];

  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState(1);
  const [dataTable, setDataTable] = useState<T[]>([]);

  const debounce = useDebounce((data) => {
    setDataTable(data.slice((page - 1) * rows, page * rows));
  }, 1);

  useEffect(() => {
    setPageCount(Math.ceil(data.length / rows));
  }, [data.length, rows]);

  useEffect(() => {
    debounce(data);
  }, [page, rows, data, debounce]);

  const prev = () => {
    setPage((prevPage) => Math.max(prevPage - 1, 1));
  };
  const next = () => {
    setPage((prevPage) => Math.min(prevPage + 1, pageCount));
  };

  return { dataTable, pageCount, page, prev, next };
};

export default useDataTable;
