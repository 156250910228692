import { useEffect } from "react";
import landing from "../assets/landing.webp";
import { Box, Flex, Grid, GridItem, Heading, Link, Text } from "@chakra-ui/react";
import { logout } from "../global";
import GameButton from "../components/control/GameButton";

const NoPlanetAvailable = () => {
  useEffect(() => {
    document.body.style.backgroundImage = `url(${landing})`;
    document.body.style.backgroundSize = "cover";
    document.body.style.backgroundPosition = "center";
    document.body.style.backgroundAttachment = "fixed";
  }, []);

  return (
    <Grid
      h="95dvh"
      templateAreas={{
        base: `
          "banner"
          "header"
          "main"
          "footer"
        `,
      }}
      templateColumns={{
        base: "1fr",
      }}
      templateRows={{
        base: "auto auto 1fr auto auto",
        md: "auto auto 1fr auto",
      }}
      gap={0}
      overflow="hidden"
    >
      <GridItem area="banner" bg="blackAlpha.500" color="white" borderTopRadius="lg"></GridItem>
      <GridItem area="header" bg="blackAlpha.500" boxShadow="sm">
        <Heading>No Open Planet Slots Currently Available</Heading>
      </GridItem>
      <GridItem area="main" bg="blackAlpha.500" boxShadow="sm" overflowY="auto">
        <Box h="100%">
          <Flex direction="column" h="100%" p={4} gap={4}>
            <Box flex="1" overflow="hidden">
              <Box p={4} bg="blackAlpha.700" borderRadius="md" mt={4} textAlign="center" overflow="auto" h="100%">
                <Text>We apologize, but it appears there are currently no available planets for you to occupy.</Text>
                <Text>Please {<Link href="mailto:help@celestialregime.com?subject=No%20open%20planet%20slots%20available">contact us</Link>} with your details, or try again later.</Text>
              </Box>
            </Box>
            <Box flex="0 1 auto">
              <Box p={2} bg="blackAlpha.700" borderRadius="md" mt={4}>
                <Grid templateColumns="repeat(3, 1fr)" gap={6}>
                  <GameButton size={{ base: "xs", "2xl": "sm" }} onClick={logout}>
                    OK
                  </GameButton>
                </Grid>
              </Box>
            </Box>
          </Flex>
        </Box>
      </GridItem>
      <GridItem area="footer" bg="blackAlpha.500" color="white" borderBottomRadius="lg"></GridItem>
    </Grid>
  );
};

export default NoPlanetAvailable;
