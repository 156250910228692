import { create } from "zustand";
import { subscribeWithSelector } from "zustand/middleware";

import WebWorker from "../web-worker";

import { PlanetDTO } from "../../structs/dtos/Empire";
import { IBanner } from "../../structs/Interface";
import { IPlayerDTO } from "../../structs/dtos/Player";

type IStoreGameState = {
  webWorker: WebWorker | undefined;
  timestamp: string;
  planets: PlanetDTO[];
  currPlanet: PlanetDTO | undefined;
  banner: IBanner | undefined;
  duplicateConnection: boolean;
  player: IPlayerDTO | undefined;
  ptcNow: string;
  setWebWorker: (webWorker: WebWorker) => void;
  setTimestamp: (timestamp: string) => void;
  setPlanets: (planets: PlanetDTO[]) => void;
  setCurrentPlanet: (currPlanet: PlanetDTO) => void;
  setBanner: (banner: IBanner) => void;
  setDuplicateConnection: (duplicateConnection: boolean) => void;
  setPlayer: (player: IPlayerDTO) => void;
  setPtcNow: (ptcNow: string) => void;
};

const useStoreGameState = create<IStoreGameState, [["zustand/subscribeWithSelector", never]]>(
  subscribeWithSelector<IStoreGameState>((set) => ({
    webWorker: undefined,
    timestamp: new Date().toUTCString(),
    planets: [],
    currPlanet: undefined,
    banner: undefined,
    duplicateConnection: false,
    player: undefined,
    ptcNow: new Date().toUTCString(),
    setWebWorker: (webWorker) => set(() => ({ webWorker })),
    setTimestamp: (timestamp) => set(() => ({ timestamp })),
    setPlanets: (planets) => set(() => ({ planets })),
    setCurrentPlanet: (currPlanet) => set(() => ({ currPlanet })),
    setBanner: (banner) => set(() => ({ banner })),
    setDuplicateConnection: (duplicateConnection) => set(() => ({ duplicateConnection })),
    setPlayer: (player) => set(() => ({ player })),
    setPtcNow: (ptcNow) => set(() => ({ ptcNow })),
  }))
);
export default useStoreGameState;
